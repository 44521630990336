import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import TableList from 'components/moderator/TableList';

import AdminFilter, { AdminFilterProps } from '../AdminFilter/view';
import AdminPage from '../AdminPage';
import { AdminPageProps } from '../AdminPage/view';

export enum SortingType {
  ASC = 'ASC',
  DESC = 'DESC',
}
export type Sorting = {
  type: SortingType;
  name: string;
};

export type SortingOptions = { label: string; value: string }[];

export type AdminTableProps<TData = any, TFilters extends FieldValues = any> = {
  // FIXME types
  storeKey: string;
  query: {
    use: UseQuery<QueryDefinition<any, any, any, any>>;
    params?: object;
    mapQueryResultToData?: (data) => TData;
  };
  filtersConfig?: {
    defaultValues: TFilters;
    renderFilters: (form: UseFormReturn<TFilters>) => ReactNode;
    mapFiltersToRequest?: (
      filters: TFilters,
      defaultMap: (filters: TFilters) => any
    ) => any;
    onDownloadCsvButtonClick?: ({
      request,
      sorting,
    }: {
      request: any;
      sorting?: Sorting;
    }) => void;
    extraButtons?: AdminFilterProps<TFilters>['extraButtons'];
  };
  sortingConfig?: {
    options: SortingOptions;
  };
  columns: any[];
  onRowClick?: ((row: TData) => void) | null;
  shouldOpenInNewTab?: boolean;
  onDataLoadSuccess?: (args) => void;
  displayCountLabel?: string;
};

export type AdminTableListPageProps<T extends object> = AdminPageProps &
  AdminTableProps;

export const defaultMapFiltersToRequest = <TFilter extends object>(
  filters: TFilter
) => {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    const val = value?.value ?? value;
    if (Array.isArray(val)) {
      if (val.length === 0) return acc;
      acc[key] = val.map(item => item.value);
      return acc;
    }
    if (val !== undefined && val !== '') {
      acc[key] = val;
    }
    return acc;
  }, {});
};

let savedFilters: Partial<any> = {};
let savedSorting: Sorting | undefined;

export const AdminTableList = <TData = any, TFilters extends object = any>({
  query: { use, params, mapQueryResultToData },
  columns,
  filtersConfig,
  sortingConfig,
  onRowClick,
  shouldOpenInNewTab,
  onDataLoadSuccess,
  displayCountLabel,
}: AdminTableProps<TData, TFilters>) => {
  const [filters, setFilters] = useState(savedFilters);
  const [sorting, setSorting] = useState(savedSorting);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const setFiltersAndSave = filters => {
    setFilters(filters);
    savedFilters = filters;
  };

  const setSortingAndSave = sorting => {
    setSorting(sorting);
    savedSorting = sorting;
  };

  const filtersForRequest = filtersConfig?.mapFiltersToRequest
    ? filtersConfig.mapFiltersToRequest(
        filters as TFilters,
        defaultMapFiltersToRequest
      )
    : defaultMapFiltersToRequest(filters);

  const queryArgs = {
    ...params,
    ...(sorting
      ? { orderBy: sorting.name, orderDirection: sorting.type }
      : undefined),
    request: filtersForRequest,
    page: currentPage,
    size: perPage,
  };
  const { data } = use(queryArgs);

  useEffect(() => {
    if (!data || !onDataLoadSuccess) return;
    onDataLoadSuccess(queryArgs);
  }, [data, onDataLoadSuccess]);

  const mappedData = useMemo(() => {
    if (!data) return [];
    if (!mapQueryResultToData) return data.result;
    return mapQueryResultToData(data.result);
  }, [data]);

  const onDownloadCsvButtonClick = filtersConfig?.onDownloadCsvButtonClick;

  return (
    <>
      {filtersConfig && (
        <AdminFilter
          onSubmit={data => {
            setCurrentPage(1);
            // Костыль, чтобы после сабмита фильтров происходил рефетч
            setFiltersAndSave({ ...data, timeStamp: Date.now() });
          }}
          onReset={() => {
            setCurrentPage(1);
            setFiltersAndSave({});
          }}
          filter={filters}
          {...filtersConfig}
          onDownloadCsvButtonClick={
            onDownloadCsvButtonClick
              ? () =>
                  onDownloadCsvButtonClick({
                    request: filtersForRequest,
                    sorting,
                  })
              : undefined
          }
          displayCount={data?.totalElements ?? 0}
          displayCountLabel={displayCountLabel}
        >
          {filtersConfig.renderFilters}
        </AdminFilter>
      )}
      <TableList
        columns={columns}
        mappedData={mappedData}
        pagination={{
          currentPage,
          lastPage: data?.totalPages ?? currentPage,
          perPage,
          onPageChange: page => setCurrentPage(page),
          onPerPageChange: perPage => setPerPage(perPage),
        }}
        showCsvDownloadButton={false}
        sorting={sorting}
        onSort={setSortingAndSave}
        sortingOptions={sortingConfig?.options}
        onRowClick={onRowClick}
        shouldOpenInNewTab={shouldOpenInNewTab}
      />
    </>
  );
};

const AdminTableListPage: FC<AdminTableListPageProps<any>> = ({
  title,
  controls,
  ...rest
}) => {
  return (
    <AdminPage title={title} controls={controls}>
      <AdminTableList {...rest} />
    </AdminPage>
  );
};

export default AdminTableListPage;
