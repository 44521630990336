import React, { FC } from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as HeaderIconEnMini } from 'assets/images/header-logo-en-mini.svg';
import { ReactComponent as HeaderIconEn } from 'assets/images/header-logo-en.svg';
import { ReactComponent as HeaderIconMini } from 'assets/images/header-logo-mini.svg';
import { ReactComponent as HeaderIcon } from 'assets/images/header-logo.svg';
import Button from 'components/common/ui-kit/Button';
import LangChanger from 'components/common/ui-kit/Header/LangChanger';
import UserMenu from 'components/common/ui-kit/Header/UserMenu';
import useAppSelector from 'hooks/common/useAppSelector';
import useWindowsSize from 'hooks/common/useWindowsSize';
import terms, { locale } from 'i18n';
import { selectAccessToken, selectUser } from 'redux/selectors';

import { UserRole } from '../../../../models/user';
import ModeratorMenu from './ModeratorMenu';

const Header: FC = () => {
  const { role } = useAppSelector(selectUser);
  const token = useAppSelector(selectAccessToken);
  const location = useLocation();
  const { isMobile } = useWindowsSize();

  return (
    <header className="header">
      <div className="header__content">
        <NavLink to="/">
          {locale === 'ru'
            ? (isMobile && <HeaderIconMini width="108" height="23" />) || (
                <HeaderIcon width="246" height="70" />
              )
            : (isMobile && <HeaderIconEnMini width="109" height="23" />) || (
                <HeaderIconEn width="246" height="70" />
              )}
        </NavLink>
        <div className="header__right-corner">
          {!token || role === UserRole.USER ? <LangChanger /> : ''}
          {token &&
            role &&
            (role === UserRole.USER ? <UserMenu /> : <ModeratorMenu />)}
          {!token && location.pathname.includes('applicants') && (
            <NavLink to="sign-in" className="btn-link">
              <Button type="button" theme="success">
                {terms.LOG_IN}
              </Button>
            </NavLink>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
