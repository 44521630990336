import React, { FC } from 'react';

import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import terms, { isRussianLocale } from 'i18n';
import { UserRole } from 'models/user';

import useAppDispatch from '../../../../hooks/common/useAppDispatch';
import useAppSelector from '../../../../hooks/common/useAppSelector';
import useEntranceExamsRowForm from '../../../../hooks/statements/entrance-tests/useEntranceExamsRowForm';
import {
  EntranceTest,
  EntranceTestStatus,
} from '../../../../models/applications/entrance-tests';
import { SelectOption } from '../../../../models/common';
import { selectUserRole } from '../../../../redux/selectors';
import Button from '../../../common/ui-kit/Button';
import ReactSelect from '../../../common/ui-kit/Select';

type EntranceExamsRowProps = {
  readonly test: EntranceTest;
  // readonly onEditSubmit: () => void;
  readonly readOnly: boolean;
  readonly applicationId;
};

const EntranceExamsRow: FC<EntranceExamsRowProps> = ({
  test,
  // onEditSubmit,
  readOnly,
  applicationId,
}) => {
  const {
    control,
    availableDatesSelectOptions,
    fields,
    hasExamDate,
    canPassExam,
    handleDateTimeClear,
  } = useEntranceExamsRowForm(test, applicationId);

  const role = useAppSelector(selectUserRole);
  const dispatch = useAppDispatch();

  // const handleEditButtonClick = () => {
  //   dispatch(
  //     showModal({
  //       name: 'EDIT_ENTRANCE_TEST_MODAL',
  //       data: {
  //         score: test.score,
  //         onSubmit: onEditSubmit,
  //         applicationEntranceTestId: test.id,
  //       },
  //     })
  //   );
  // };

  return (
    <tr className="entry-exams-table__row">
      <td className="entry-exams-table__cell">
        <span className="entry-exams-table__discipline">
          {isRussianLocale() ? test.subject.title : test.subject.titleForeign}
        </span>
      </td>
      <td className="entry-exams-table__cell">
        <Controller
          name={fields.dateTime.name}
          control={control}
          render={({ field }) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <ReactSelect<SelectOption>
                placeholder={terms.EXAM_DATE}
                className="students-form__field"
                isDisabled={readOnly}
                markRequired={true}
                {...(field as object)}
                options={availableDatesSelectOptions}
              />
              {(role === UserRole.ADMIN ||
                role === UserRole.INSTITUTE_STAFF) && (
                <>
                  <button
                    type="button"
                    className="delete-button"
                    onClick={handleDateTimeClear}
                    disabled={readOnly}
                  >
                    <TrashIcon />
                  </button>
                  {/* <button
                    type="button"
                    className="delete-button"
                    onClick={handleEditButtonClick}
                    disabled={readOnly}
                  >
                    <EditIcon />
                  </button>*/}
                </>
              )}
            </div>
          )}
        />
      </td>

      <td
        className={classnames(
          'entry-exams-table__cell',
          'entry-exams-table__cell--align-center'
        )}
      >
        <span className="entry-exams-table__estimate">
          {test?.score ?? terms.NOT_GRADED}
          {test?.score !== undefined && test?.setByModer && (
            <>
              <Tooltip
                anchorSelect=".set-by-moder-mark"
                clickable
                style={{ maxWidth: 400, padding: '10px 15px' }}
                place="top"
                variant="dark"
              >
                {'Оценка выставлена вручную'}
              </Tooltip>
              <span
                className="set-by-moder-mark"
                style={{ color: '#37b34a', fontSize: '1.2rem' }}
              >
                *
              </span>
            </>
          )}
        </span>
      </td>

      <td
        className={classnames(
          'entry-exams-table__cell',
          'entry-exams-table__cell--align-center'
        )}
      >
        {test.status === EntranceTestStatus.PASSED && (
          <span className="entry-exams-table__date">{terms.EXAM_PASSED}</span>
        )}

        {test.status === EntranceTestStatus.FAILED && (
          <span className="entry-exams-table__date">{terms.EXAM_FAILED}</span>
        )}

        {test.status === EntranceTestStatus.NOT_STARTED &&
          (canPassExam ? (
            <Button
              className="start-button"
              type="button"
              theme="success"
              // disabled={readOnly}
              onClick={() => window.open(test?.moodleLink, '_blank')}
            >
              {terms.EXAM_START}
            </Button>
          ) : hasExamDate ? (
            <span className="entry-exams-table__date">
              {terms.WAIT_EXAM_DATE}
            </span>
          ) : (
            <span className="entry-exams-table__date">
              {terms.SELECT_EXAM_DATE}
            </span>
          ))}
      </td>
      {role === UserRole.ADMIN && (
        <td
          className={classnames(
            'entry-exams-table__cell',
            'entry-exams-table__cell--align-center'
          )}
        >
          <Button
            disabled={!test.statementId}
            onClick={() =>
              window.open(`/admin/registry/${test.statementId}`, '_blank')
            }
          >
            Посмотреть в ведомости
          </Button>
        </td>
      )}
    </tr>
  );
};

export default EntranceExamsRow;
