import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import terms, { isRussianLocale } from 'i18n';
import {
  ApplicationByStaff,
  OriginalEducationDocumentSubmissionType,
} from 'models/applications/applications';
import { ErrorResponse, ErrorResponseType } from 'models/applications/errors';
import { mapApplicationStateToText } from 'pages/moderator/ModeratorStatementsPage/view';
import { createToast, showModal } from 'redux/actions';
import {
  useGetOriginalEducationDocumentSubmissionQuery,
  useRevokeOriginalEducationDucumentMutation,
  useSubmitOriginalEducationDucumentMutation,
} from 'redux/api/applications/applications-api';

import { ApplicationState } from '../../../models/applications/enum';
import { SelectOption } from '../../../models/common';
import { COUNTRY_RUSSIA_ID } from '../../../models/enum';
import {
  useForceWithdrawApplicationMutation,
  useSetApplicationHasOriginalDocsMutation,
  useSetModeratorForApplicationMutation,
} from '../../../redux/api/staff/admin/admin-applications-api';
import {
  coordinatorApplicationsApi,
  useApproveApplicationByCoordinatorMutation,
  useRejectApplicationByCoordinatorMutation,
} from '../../../redux/api/staff/coordinator/coordinator-applications-api';
import {
  expertApplicationsApi,
  useApproveApplicationByExpertMutation,
  useRejectApplicationByExpertMutation,
} from '../../../redux/api/staff/expert/expert-applications-api';
import {
  useAcceptApplicationMutation,
  useAllowApplicationMutation,
  useApproveApplicationWithdrawMutation,
  useClearErrorMutation,
  useLazyGetStaffApplicationByIdQuery,
  useRejectApplicationEditingMutation,
  useRejectApplicationMutation,
  useRejectApplicationWithdrawMutation,
  useSendApplicationToCoordinatorEditingMutation,
  useSendApplicationToEditingMutation,
  useSendToQueueMutation,
  useSetApplicationCommentMutation,
  useTakeToWorkApplicationMutation,
} from '../../../redux/api/staff/staff-applications-api';
import { selectUser } from '../../../redux/selectors';
import {
  selectCurrentApplication,
  selectCurrentQuestionnaire,
} from '../../../redux/selectors/staff/applications';
import { selectCurrentSupportQuestionnaire } from '../../../redux/selectors/staff/questionnaires';
import { staffApplicationsSlice } from '../../../redux/slices/staff/staffApplicationsSlice';
import useAppDispatch from '../../common/useAppDispatch';
import useAppSelector from '../../common/useAppSelector';

export const useIsEnrolleeRussian = (isSupport = false) => {
  const questionnaire = useSelector(
    isSupport ? selectCurrentSupportQuestionnaire : selectCurrentQuestionnaire
  );
  return (
    questionnaire?.generalDataForm.primaryCitizenshipCountry?.id ===
    COUNTRY_RUSSIA_ID
  );
};

export const useModeratorApplication = (moderators: SelectOption[]) => {
  const dispatch = useAppDispatch();

  const isEnrolleeRussian = useIsEnrolleeRussian();
  const application = useAppSelector(selectCurrentApplication);

  const { role } = useAppSelector(selectUser);

  const [getApplication, { data: updatedApplication }] =
    useLazyGetStaffApplicationByIdQuery();

  const [takeToWorkApplication] = useTakeToWorkApplicationMutation();
  // const [sendApplicationToExpert] = useSendApplicationToExpertMutation();
  const [acceptApplication] = useAcceptApplicationMutation();
  const [allowApplication] = useAllowApplicationMutation();
  const [rejectApplication] = useRejectApplicationMutation();
  const [rejectApplicationEditing] = useRejectApplicationEditingMutation();
  const [sendApplicationToEditing] = useSendApplicationToEditingMutation();
  const [setCommentForApplication] = useSetApplicationCommentMutation();
  const [sendApplicationToCoordinatorEditing] =
    useSendApplicationToCoordinatorEditingMutation();
  const [approveWithdraw] = useApproveApplicationWithdrawMutation();
  const [rejectWithdraw] = useRejectApplicationWithdrawMutation();
  const [forceWithdraw] = useForceWithdrawApplicationMutation();

  const [approveApplicationByCoordinator] =
    useApproveApplicationByCoordinatorMutation();
  const [rejectApplicationByCoordinator] =
    useRejectApplicationByCoordinatorMutation();
  const [takeToWorkByCoordinatorMutation] =
    coordinatorApplicationsApi.useTakeApplicationToWorkByCoordinatorMutation();

  const [approveApplicationByExpert] = useApproveApplicationByExpertMutation();
  const [rejectApplicationByExpert] = useRejectApplicationByExpertMutation();
  const [takeToWorkByExpertMutation] =
    expertApplicationsApi.useTakeApplicationToWorkByExpertMutation();

  const [setModeratorForApplication] = useSetModeratorForApplicationMutation();
  const [setApplicationHasOriginalDocs] =
    useSetApplicationHasOriginalDocsMutation();
  const [sendToQueueMutate] = useSendToQueueMutation();
  const [clearErrorMutate] = useClearErrorMutation();

  const questionnaire = useSelector(selectCurrentSupportQuestionnaire);

  useEffect(() => {
    if (updatedApplication) {
      dispatch(
        staffApplicationsSlice.actions.setCurrentApplication({
          application: updatedApplication,
        })
      );
    }
  }, [updatedApplication]);

  useEffect(() => {
    if (application) {
      reset({
        comment: application.comment ?? '',
        haveOriginalDocs: application.hasOriginalDocuments,
        moderator:
          moderators.find(staff => staff.value === application.moderatorId) ??
          undefined,
      });
    }
  }, [application]);

  const { register, handleSubmit, reset, control } = useForm<{
    comment: string;
    haveOriginalDocs?: boolean;
    moderator?: SelectOption;
  }>({
    mode: 'all',
  });

  const navLink = () => {
    if (!application) return '';

    if (location.pathname.includes('personal-information')) {
      return `/admin/users/impersonate/${application.userId}`;
    } else {
      return `/admin/users/impersonate/${application.userId}/${application.id}`;
    }
  };

  const fields = {
    comment: register('comment', {
      onBlur: () => onSubmit(),
    }),
    haveOriginalDocs: register('haveOriginalDocs', {
      onChange: ({ target: { checked } }) => {
        if (!application) return;

        setApplicationHasOriginalDocs({
          applicationId: application.applicationNumber,
          status: checked,
        })
          .unwrap()
          .then(() => getApplication(application.applicationNumber));
      },
    }),
    moderator: register('moderator'),
  };

  const processWithUpdate = (
    mutation,
    extraOnError?: (error: any, application: ApplicationByStaff) => void
  ) => {
    if (!application) return;
    mutation(application.applicationNumber)
      .unwrap()
      .then(() => getApplication(application.applicationNumber))
      .catch(e => {
        extraOnError?.(e, application);
        if (e.data?.message && e.data?.messageRu) {
          dispatch(
            createToast(
              isRussianLocale() ? e.data.messageRu : e.data.message,
              'danger'
            )
          );
          return;
        }
        dispatch(createToast(terms.ERROR_HAS_OCCURRED, 'danger'));
      });
  };

  const takeToWork = () => processWithUpdate(takeToWorkApplication);

  const sendToExpert = () => {
    dispatch(
      showModal({
        name: 'EXPERT_EXAMINE_REFERRAL',
        data: {
          applicationId: application?.applicationNumber,
          postSubmit: () => {
            if (application !== undefined)
              getApplication(application.applicationNumber);
          },
        },
      })
    );
  };

  const sendToCoordinator = () =>
    processWithUpdate(sendApplicationToCoordinatorEditing);

  const deleteApplication = () => {};

  const changeModerator = moderatorId => {
    if (!application) return;
    setModeratorForApplication({
      applicationId: application.applicationNumber,
      moderatorId: moderatorId,
    })
      .unwrap()
      .then(() => getApplication(application.applicationNumber));
  };

  const reject = (reasonRejectExternalId: number | void) => {
    if (!application) return;

    rejectApplication({
      applicationId: application.applicationNumber,
      reasonRejectExternalId:
        reasonRejectExternalId ?? application?.epguInfo?.reasonRejectExternalId,
    })
      .unwrap()
      .then(() => getApplication(application.applicationNumber));
  };
  const rejectEditing = () => processWithUpdate(rejectApplicationEditing);

  const sendToEditing = () => processWithUpdate(sendApplicationToEditing);

  const accept = () =>
    processWithUpdate(acceptApplication, (error, application) => {
      const errorType = (error.data as ErrorResponse | undefined)?.errorType;
      if (errorType !== ErrorResponseType.NEED_FORCE) return;
      dispatch(
        showModal({
          name: 'FORCE_ACCEPT_APPLICATION_MODAL',
          data: {
            applicationId: application.id,
            onSuccess: () => getApplication(application.applicationNumber),
          },
        })
      );
    });

  const allow = () => processWithUpdate(allowApplication);

  const sendToQueue = () => {
    if (!application) return;
    sendToQueueMutate(application.id)
      .unwrap()
      .then(() => {
        dispatch(
          createToast('Заявление отправлено на повторную выгрузку', 'success')
        );
        getApplication(application.applicationNumber);
      })
      .catch(() =>
        dispatch(
          createToast(
            'Во время отправки на выгрузку произошла ошибка',
            'danger'
          )
        )
      );
  };

  const clearError = () => {
    if (!application) return;
    clearErrorMutate(application.id)
      .unwrap()
      .then(() => {
        dispatch(createToast('Ошибка очищена', 'success'));
        getApplication(application.applicationNumber);
      })
      .catch(() =>
        dispatch(createToast('Не удалось очистить ошибку', 'danger'))
      );
  };

  const acceptWithdraw = () => processWithUpdate(approveWithdraw);

  const declineWithdraw = () => processWithUpdate(rejectWithdraw);
  const withdrawByAdmin = () => processWithUpdate(forceWithdraw);

  const takeToWorkByExpert = () =>
    processWithUpdate(takeToWorkByExpertMutation);

  const rejectByExpert = () => processWithUpdate(rejectApplicationByExpert);

  const approveByExpert = () => processWithUpdate(approveApplicationByExpert);

  const rejectByCoordinator = () =>
    processWithUpdate(rejectApplicationByCoordinator);

  const approveByCoordinator = () =>
    processWithUpdate(approveApplicationByCoordinator);

  const takeToWorkByCoordinator = () =>
    processWithUpdate(takeToWorkByCoordinatorMutation);

  const onSubmit = handleSubmit(data => {
    if (!application) return;

    setCommentForApplication({
      id: application.applicationNumber,
      comment: data.comment,
    })
      .unwrap()
      .then(() => getApplication(application.applicationNumber));
  });

  const [submitOriginalDocument] = useSubmitOriginalEducationDucumentMutation();
  const [revokeOriginalDocument] = useRevokeOriginalEducationDucumentMutation();

  const { data: originalSubmission, refetch } =
    useGetOriginalEducationDocumentSubmissionQuery(application?.id ?? 0, {
      skip: !application,
    });

  const [selectedEducationDocumentType, setSelectedEducationDocument] =
    useState<OriginalEducationDocumentSubmissionType | null>(null);

  useEffect(() => {
    if (!originalSubmission) return;
    setSelectedEducationDocument(
      originalSubmission.isSubmitted ? originalSubmission.type : null
    );
  }, [originalSubmission]);

  const handleOriginalEducationDocumentTypeChange = (
    value: OriginalEducationDocumentSubmissionType | null
  ) => {
    if (!application) return;
    if (value === selectedEducationDocumentType) return;
    setSelectedEducationDocument(value);
    if (value === null) {
      revokeOriginalDocument(application.id)
        .unwrap()
        .then(() => {
          dispatch(createToast(terms.SUCCESS_REVOKING_ORIGINAL, 'success'));
          dispatch(
            staffApplicationsSlice.actions.setCurrentApplication({
              application: {
                ...application,
                hasOriginalDocuments: false,
              },
            })
          );
          refetch();
        })
        .catch(() => {
          dispatch(createToast(terms.ERROR_REVOKING_ORIGINAL, 'danger'));
          if (!originalSubmission) return;
          setSelectedEducationDocument(originalSubmission.type);
        });
      return;
    }
    submitOriginalDocument({
      applicationId: application.id,
      request: { type: value },
    })
      .unwrap()
      .then(() => {
        dispatch(createToast(terms.SUCCESS_SUBMITTING_ORIGINAL, 'success'));
        dispatch(
          staffApplicationsSlice.actions.setCurrentApplication({
            application: {
              ...application,
              hasOriginalDocuments: true,
            },
          })
        );
        refetch();
      })
      .catch(() => {
        dispatch(createToast(terms.ERROR_SUBMITTING_ORIGINAL, 'danger'));
        if (!originalSubmission) return;
        setSelectedEducationDocument(originalSubmission.type);
      });
  };

  return {
    fields,
    role,
    control,
    expertId: application?.expertId,
    coordinatorId: application?.coordinatorId,
    takeToWork,
    sendToExpert,
    sendToCoordinator,
    deleteApplication,
    changeModerator,
    rejectApplication: reject,
    rejectApplicationEditing: rejectEditing,
    sendToEditing,
    sendToQueue,
    clearError,
    acceptApplication: accept,
    allowApplication: allow,
    acceptWithdraw,
    declineWithdraw,
    withdrawByAdmin,
    rejectByCoordinator,
    rejectByExpert,
    approveByExpert,
    takeToWorkByExpert,
    takeToWorkByCoordinator,
    approveByCoordinator,
    userId: application?.userId,
    applicationId: application?.id,
    state: application?.state ?? ApplicationState.DRAFT,
    expertState: application?.expertStatus,
    canSendToExpert:
      !isEnrolleeRussian ||
      questionnaire?.educationalInformationForm[0].educationCountry?.id !==
        COUNTRY_RUSSIA_ID,
    coordinatorState: application?.coordinatorStatus,
    navLink,
    stateTitle: mapApplicationStateToText(
      application?.state,
      application?.flags
    ),
    selectedEducationDocumentType,
    originalSubmission,
    handleOriginalEducationDocumentTypeChange,
  };
};
