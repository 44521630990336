import { DateString } from '../common';
import { ExternalEntityNamedResponseWithExternalId } from './contracts';
import { DirectionApplicationEducationLevel } from './enum';
import { Privilege } from './privileges';
import { SubjectResponse } from './school-tests';

export type DirectionEnum = {
  id: number;
  title: string;
  titleForeign: string;
};

export type Faculty = {
  id: number;
  title: string;
  titleForeign: string;
  externalId: string;
  titleShort: string;
};

export type EducationProgram = {
  id: number;
  code: string;
  title: string;
  titleForeign: string;
};

export type EntranceTestsSet = {
  id: number;
  subjects: SubjectResponse[];
  title: string;
  titleForeign: string;
};

export type TargetOrganization = {
  id: number;
  address: string;
  email: string;
  epguExternalId: string;
  inn: string;
  kpp: string;
  ogrn: string;
  name: string;
  shortName: string;
  telephone: string;
};

export type Direction = {
  id: number;
  code: string;
  title: string;
  asBenefit: boolean;
  asQuota: boolean;
  titleForeign: string;
  applicationEducationLevel?: DirectionApplicationEducationLevel;
  educationForm: DirectionEnum;
  paymentForm: DirectionEnum;
  budgetLevel?: string;
  educationLevel: DirectionEnum;
  educationProgram?: EducationProgram;
  targetOrganization?: TargetOrganization;
  entranceTestsSets: EntranceTestsSet[];
  faculty: ExternalEntityNamedResponseWithExternalId & { titleShort: string };
  isContract: boolean;
  forForeign: boolean;
  educationLanguageName: string;
  closed: boolean;
};

export type DirectionEntranceTestPriority = {
  directionId: number;
  subjectId: number;
  priotity: number;
};

export type DirectionFilters = {
  name?: string;
  educationLevelId?: string;
  applicationId?: number;
  educationFormId?: number;
  paymentFormId?: number;
  admissionBasis?: AdmissionBasis;
  showClosed?: boolean;
};

enum TargetAgreeStatus {
  REFUSED_BY_EMPLOYER = 'REFUSED_BY_EMPLOYER', // Заявка отклонена Заказчиком
  REFUSED_DUE_TO_EXPIRATION = 'REFUSED_DUE_TO_EXPIRATION', // Заявка отклонена в связи с истечением срока рассмотрения
  ACCEPTED_BY_EMPLOYER = 'ACCEPTED_BY_EMPLOYER', // Заявка одобрена Заказчиком
  UNDER_CONSIDERATION_BY_EMPLOYER = 'UNDER_CONSIDERATION_BY_EMPLOYER', // На рассмотрении у заказчика
  REVOKED_BY_APPLICANT = 'REVOKED_BY_APPLICANT', // Заявка отозвана гражданином
}

export type ChosenDirectionWithPriority = {
  id: number;
  applicationId: number;
  priority: number;
  agreement?: boolean;
  direction: Direction;
  createdAt?: DateString;
  deletedAt?: DateString;
  refuseEnrollmentDate?: DateString;
  profileSubjectScore?: number | null;
  rtTargetAgreeStatus?: TargetAgreeStatus | null;
  rtTargetAgreeCreatedAt?: DateString | null;
  selectedPrivilege?: Privilege;
  admitted?: boolean;
  entranceTestsSatisfied?: boolean;
  privilegeAdmitted?: boolean;
  targetContractPresent?: boolean;
};

export enum AdmissionBasis {
  BUDGET = 'BUDGET', // Бюджетная основа
  CONTRACT = 'CONTRACT', // Контракт
  FOREIGN = 'FOREIGN', // Как иностранный гражданин
  SPECIAL_QUOTA = 'SPECIAL_QUOTA', // Особая квота
  EXTRA_QUOTA = 'EXTRA_QUOTA', // Отдельная квота
  QUOTA = 'QUOTA', // По направлению Минобрнауки
  GOVERNMENT = 'GOVERNMENT', // Целевой прием
}

export const admissionBasesToLabels: { [key in AdmissionBasis]: string } = {
  [AdmissionBasis.BUDGET]: 'Бюджетная основа',
  [AdmissionBasis.CONTRACT]: 'Контракт',
  [AdmissionBasis.FOREIGN]: 'Как иностранный гражданин',
  [AdmissionBasis.SPECIAL_QUOTA]: 'Особая квота',
  [AdmissionBasis.EXTRA_QUOTA]: 'Отдельная квота',
  [AdmissionBasis.QUOTA]: 'По направлению Минобрнауки',
  [AdmissionBasis.GOVERNMENT]: 'Целевой прием',
};
