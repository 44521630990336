import React, { FC, memo, useCallback, useState } from 'react';

import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as AttachmentIcon } from 'assets/images/icons/attachment.svg';
import { ReactComponent as SendIcon } from 'assets/images/icons/send-icon.svg';
import Attachments from 'components/chat/ChatWidget/ChatPanel/Attachments';
import MessageInput from 'components/chat/ChatWidget/ChatPanel/MessageForm/MessageInput';
import useChatAttachments from 'hooks/chat/useChatAttachments';
import useChatSendMessage from 'hooks/chat/useChatSendMessage';
import terms from 'i18n';

type MessageFormProps = {
  className?: string;
};

const MessageForm: FC<MessageFormProps> = ({ className }) => {
  const sendChatMessage = useChatSendMessage();
  const [message, setMessage] = useState('');

  const {
    attachments,
    chatAttachments,
    onAddAttachments,
    deleteAttachment,
    clearAttachments,
    isLoading,
  } = useChatAttachments();

  const { getRootProps, getInputProps, isDragActive, open, rootRef } =
    useDropzone({
      onDrop: useCallback(onAddAttachments, []),
      noClick: true,
      maxSize: 5000000,
    });

  const isMessageReady = () =>
    (!!message?.trim() || !!chatAttachments.length) && !isLoading;

  const sendMessage = e => {
    e.preventDefault();
    if (isMessageReady()) {
      setMessage('');
      sendChatMessage(message.trim(), chatAttachments);
      clearAttachments();
    }
  };

  return (
    <form
      {...getRootProps({
        className: classNames('message-form', className),
        onSubmit: sendMessage,
      })}
    >
      <input {...getInputProps()} />
      <div className="message-form__attachment" onClick={open}>
        <AttachmentIcon />
      </div>
      <div className="message-form__middle">
        {isDragActive ? (
          <div className="message-form__dropzone">{terms.CHAT_FILE_UPLOAD}</div>
        ) : (
          <MessageInput
            message={message}
            setMessage={setMessage}
            isMessageReady={isMessageReady()}
            submitForm={() =>
              (rootRef.current as HTMLFormElement | undefined)?.requestSubmit()
            }
          />
        )}
        <Attachments
          senderAttachments={attachments}
          deleteSenderAttachment={deleteAttachment}
          className="message-form__attachments"
        />
      </div>
      <button
        type="submit"
        className={classNames('message-form__send', {
          'message-form__send--disabled': !isMessageReady(),
        })}
      >
        <SendIcon />
      </button>
    </form>
  );
};

export default memo(MessageForm);
