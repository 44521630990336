import {
  BaseQueryApi,
  QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { Mutex } from 'async-mutex';

import { isRussianLocale } from '../../i18n';
import { RefreshResp, RefreshRespForbidden } from '../../models/auth';
import {
  deleteAuthCredentialsFromStorage,
  getAuthCredentialsFromStorage,
  saveAuthCredentialsToStorage,
} from '../../utils/auth-token';
import {
  clearAuthCredentials,
  setAuthCredentials,
} from '../slices/common/auth-slice';
import { RootState } from '../store';

const ACCEPT_LANGUAGE_HEADER = 'Accept-Language';

export const prepareHeadersWithAuth =
  (
    next?:
      | ((
          headers: Headers,
          api: Pick<
            BaseQueryApi,
            'getState' | 'extra' | 'endpoint' | 'type' | 'forced'
          >
        ) => MaybePromise<Headers>)
      | undefined
  ) =>
  (headers, api) => {
    if (api.endpoint === 'refresh') return headers;

    const accessToken = getAuthCredentialsFromStorage()?.accessToken;

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    isRussianLocale()
      ? headers.set(ACCEPT_LANGUAGE_HEADER, 'ru')
      : headers.set(ACCEPT_LANGUAGE_HEADER, 'en');

    return next ? next(headers, api) : headers;
  };

const baseQuery = fetchBaseQuery({
  prepareHeaders: prepareHeadersWithAuth(),
});

const mutex = new Mutex();

export const baseQueryWithReAuth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshToken = (api.getState() as RootState).auth.refreshToken;
        const refreshResult = (await baseQuery(
          {
            url: `${process.env.REACT_APP_USER_MANAGER_API_URL}/auth/refresh`,
            body: { refreshToken: refreshToken },
            method: 'POST',
          },
          { ...api, endpoint: 'refresh' },
          extraOptions
        )) as QueryReturnValue<Exclude<RefreshResp, RefreshRespForbidden>>;

        if (refreshResult?.data && !refreshResult.data.needToChangePassword) {
          api.dispatch(setAuthCredentials(refreshResult.data.access));
          saveAuthCredentialsToStorage(refreshResult.data.access);
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(clearAuthCredentials());
          deleteAuthCredentialsFromStorage();
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: builder => ({}),
  tagTypes: [
    'Olympiad',
    'Achievement',
    'AchievementForm',
    'Privilege',
    'SchoolTestDocument',
    'Contract',
    'Statement',
    'StatementById',
    'UsersByAdmin',
    'getApplicationChecklist',
    'PriorityRights',
    'IsEnrolleeExists',
    'Enrollee',
    'Questionnaire',
    'School tests',
    'ApplicationByStaff',
    'CheckList',
  ],
});
