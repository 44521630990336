import React, { FC } from 'react';

import { ReactComponent as FileIcon } from 'assets/images/icons/file.svg';

import terms from '../../../i18n';

type ModeratorFileProps = {
  title: string;
  content?: string;
  upload: () => void;
};

const ModeratorFile: FC<ModeratorFileProps> = ({ title, content, upload }) => (
  <div className="moderator-file">
    <FileIcon width="61" height="61" onClick={upload} />
    <div>
      <h5>{title}</h5>
      {content && (
        <p>
          {terms.CREATED}: {content}
        </p>
      )}
    </div>
  </div>
);

export default ModeratorFile;
