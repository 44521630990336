import { DateTimeString } from 'models/common';

export const createDate = (): string => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}.${month <= 9 ? '0' + month : month}.${year}`;
};

export const formatDateTimeToRuFormat = (date: string) => {
  if (!date) return '-';
  const GMTDate = date.includes('Z') ? date : `${date}Z`;
  return new Date(GMTDate).toLocaleString('ru').slice(0, -3).replace(',', '');
};

export const formatDateYYYYMMDD = (date: string) => {
  if (!date) return '-';
  return `${date.slice(-2)}.${date.slice(5, 7)}.${date.slice(0, 4)}`;
};

export const formatDateTimeToLocaleString = (
  date: string | Date,
  locale = 'ru'
) => {
  if (!date) return '-';

  const parsedDate = new Date(date);
  if (isNaN(parsedDate.valueOf())) return '-';

  return parsedDate
    .toLocaleString(locale, {
      dateStyle: 'short',
      timeStyle: 'short',
      hourCycle: 'h23',
    })
    .replace(',', '');
};

export const convertUtcDateToLocalDate = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

/* Дальше новая итерация функций для работы со временем */
const commonFormatterOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const moscowDateTimeFormatter = new Intl.DateTimeFormat('ru-RU', {
  timeZone: 'Europe/Moscow',
  ...commonFormatterOptions,
});

const localDateTimeFormatter = new Intl.DateTimeFormat('ru-RU', {
  ...commonFormatterOptions,
});

// Когда с сервера приходит что-то вроде 2024-03-11T10:00:00,
// cчитаем, что это московское, а не гринвичское (и уж тем более не локальное) время
const getMoscowDateTimeFromStringWithoutTimeZone = (dateTime: DateTimeString) =>
  new Date(`${dateTime}+03:00`);

// Использовать, когда нужно вывести время по Москве
export const getMoscowDateTimeFormattedString = (
  dateTimeString: DateTimeString,
  getDateTime: (
    string: DateTimeString
  ) => Date = getMoscowDateTimeFromStringWithoutTimeZone
) => {
  return moscowDateTimeFormatter
    .format(getDateTime(dateTimeString))
    .replace(',', '');
};

// Использовать, когда нужно вывести локальное время
export const getLocalDateTimeFormattedString = (
  dateTimeString: DateTimeString,
  getDateTime: (
    string: DateTimeString
  ) => Date = getMoscowDateTimeFromStringWithoutTimeZone
) => {
  return localDateTimeFormatter
    .format(getDateTime(dateTimeString))
    .replace(',', '');
};
