import { DateTimeString } from '../common';

export enum AuditEventTypeHead {
  APPLICATION = 'APPLICATION',
  USER = 'USER',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
}

export enum AuditEventType {
  APPLICATION_STATUS_UPDATE = 'APPLICATION_STATUS_UPDATE',
  APPLICATION_AGREEMENT_UPDATE = 'APPLICATION_AGREEMENT_UPDATE',
  APPLICATION_CREATION = 'APPLICATION_CREATION',
  APPLICATION_CONTRACT_UPDATE = 'APPLICATION_CONTRACT_UPDATE',
  APPLICATION_DIRECTIONS_UPDATE = 'APPLICATION_DIRECTIONS_UPDATE',
  APPLICATION_MINOR_UPDATE = 'APPLICATION_MINOR_UPDATE',
  USER_REGISTERED = 'USER_REGISTERED',
  QUESTIONNAIRE_SAVED = 'QUESTIONNAIRE_SAVED',
  QUESTIONNAIRE_TAKEN = 'QUESTIONNAIRE_TAKEN',
}

export type AuditUser = {
  id: number;
  firstName?: string;
  firstNameForeign?: string;
  secondName?: string;
  secondNameForeign?: string;
  lastName?: string;
  lastNameForeign?: string;
  role?: string;
};

export type AuditEvent = {
  id: number;
  eventType: AuditEventType;
  eventTime: DateTimeString;
  applicant: AuditUser;
  actor?: AuditUser;
  applicationId?: number;
  message: string;
};

export type AuditFilters = {
  applicationId?: number;
  applicantId?: number;
  actorId?: number;
  actorRole?: string;
  startDate?: DateTimeString;
  endDate?: DateTimeString;
  eventType?: AuditEventType;
  eventTypeHead?: AuditEventTypeHead;
  message?: string;
};
