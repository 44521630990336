import { DateString, DateTimeString } from 'models/common';
import { EducationalLevel } from 'models/staff/upload-queue';

import { ApplicationState } from './enum';

export type ExamStatementResponse = {
  id: number;
  name?: string;
  subjectTitle: string;
  campaignId: string;
  campaignName: string;
  dateOfTest: DateString;
  timeOfTest: string;
  dateTimeOfTest: DateTimeString;
  uploadDate: DateTimeString | null;
  uploadError: string | null;
  moodleCourseLink?: string | null;
  approved: boolean;
  jsonToUpload?: string | null;
  quantity: number;
  numberOfUsers: number;
};

export type UserWithScoreResponse = {
  user: UserResponse;
  testId: number;
  testStatus: UserEntranceTestStatus;
  score?: number;
  userMoodleCourseLink?: string | null;
  uploadDate?: DateString | null;
  setByModer: boolean;
  applicationState: ApplicationState;
};

export type UserResponse = {
  id: number;
  externalId?: string;
  moodleId?: number;
  name?: string;
  surname?: string;
  patronymic?: string;
  email: string;
  citizenshipCountryId: number;
  educationLevel: EducationalLevel[];
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  // TODO wtf is this?
  // unreadMessagesCount: Map<UserRole, Int>;
};

export enum UserEntranceTestStatus {
  NOT_STARTED = 'NOT_STARTED',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
}

export enum ExamStatementUploadStatus {
  NOT_UPLOADED = 'NOT_UPLOADED', // Не выгружено в 1С
  UPLOAD_ERROR = 'UPLOAD_ERROR', // Выгружено с ошибкой
  UPLOADED = 'UPLOADED', // Выгружено в 1С
  //    WAITING, // Выгружено, в ожидании
}
