import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import AuthChecker from 'components/common/containers/AuthChecker';
import RequireAuth from 'components/common/containers/RequireAuth';
import RequireStaffRole from 'components/common/containers/RequireStaffRole';
import Navbar from 'components/common/ui-kit/Navbar';
import SubmitDirectionAgreementForm from 'components/statements/SubmitDirectionAgreementForm';
import { ApplicationEducationLevel } from 'models/applications/enum';
import { UserRole } from 'models/user';
import AdditionalFilesPage from 'pages/AdditionalFilesPage';
import AdministrationPage from 'pages/admin/AdministrationPage';
import DirectoryPage from 'pages/admin/DirectoryPage';
import DocumentTemplatesPage from 'pages/admin/DocumentTemplatesPage';
import LogPage from 'pages/admin/LogPage';
import RegistryEntryPage from 'pages/admin/RegistryEntryPage';
import RegistryPage from 'pages/admin/RegistryPage';
import UploadQueueEntryPage from 'pages/admin/UploadQueueEntryPage';
import UploadQueuePage from 'pages/admin/UploadQueuePage';
import UsersPage from 'pages/admin/UsersPage';
import { AdmissionCampaignPage } from 'pages/AdmissionCampaign';
import ConfirmAccountPage from 'pages/ConfirmAccountPage';
import DirectoryInfo from 'pages/Directories/DirectoryInfo';
import EnrollListsPage from 'pages/Enrollment/EnrollListsPage/current';
import LegacyEnrollListsPage from 'pages/Enrollment/EnrollListsPage/legacy';
import EnrollmentRatingsPage from 'pages/Enrollment/EnrollmentRatingsPage';
import Moderator1cPage from 'pages/moderator/Moderator1cPage';
import ModeratorActualAddressPage from 'pages/moderator/ModeratorActualAddressPage';
import ModeratorAdditionalFormsPage from 'pages/moderator/ModeratorAdditionalFormsPage';
import ModeratorBasicDataPage from 'pages/moderator/ModeratorBasicDataPage';
import ModeratorContractIdPage from 'pages/moderator/ModeratorContractIdPage';
import ModeratorContractPage from 'pages/moderator/ModeratorContractPage';
import ModeratorDirectionsPage from 'pages/moderator/ModeratorDirectionsPage';
import ModeratorDocumentsPage from 'pages/moderator/ModeratorDocumentsPage';
import ModeratorDownloadsPage from 'pages/moderator/ModeratorDownloadsPage';
import ModeratorEducationPage from 'pages/moderator/ModeratorEducationPage';
import ModeratorExamResultPage from 'pages/moderator/ModeratorExamResultPage';
import ModeratorLegalRepresentativePage from 'pages/moderator/ModeratorLegalRepresentativePage';
import ModeratorPassportDataPage from 'pages/moderator/ModeratorPassportDataPage';
import ModeratorPersonalInformationIdPage from 'pages/moderator/ModeratorPersonalInformationIdPage';
import ModeratorPersonalInformationPage from 'pages/moderator/ModeratorPersonalInformationPage';
import ModeratorRegistrationAddressPage from 'pages/moderator/ModeratorRegistrationAddressPage';
import ModeratorStatementsIdPage from 'pages/moderator/ModeratorStatementsIdPage';
import ModeratorStatementsPage from 'pages/moderator/ModeratorStatementsPage';
import ModeratorVisaPage from 'pages/moderator/ModeratorVisaPage';
import SupportBasicDataPage from 'pages/moderator/SupportBasicDataPage';
import ModeratorSignInPage from 'pages/ModeratorSignInPage';
import NotFound404 from 'pages/NotFound404';
import CompatriotDataPage from 'pages/profile/CompatriotDataPage';
import EducationDataPage from 'pages/profile/EducationDataPage';
import LegalRepresentativePage from 'pages/profile/LegalRepresentativePage';
import PassportDataPage from 'pages/profile/PassportDataPage';
import PersonalDataPage from 'pages/profile/PersonalDataPage';
import ProfilePage from 'pages/profile/ProfilePage';
import ResidencePage from 'pages/profile/ResidencePage';
import VisaPage from 'pages/profile/VisaPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import SignInPage from 'pages/SignInPage';
import SignUpPage from 'pages/SignUpPage';
import AchievementsPage from 'pages/statements/AchievementsPage';
import ContractPage from 'pages/statements/ContractPage';
import CreateContractPage from 'pages/statements/CreateContractPage';
import DirectionsTrainingCreatePage from 'pages/statements/DirectionsTrainingCreatePage';
import DirectionsPage from 'pages/statements/DirectionsTrainingListPage';
import DirectionsTrainingPage from 'pages/statements/DirectionsTrainingPage';
import DirectionsTypePage from 'pages/statements/DirectionsTypePage';
import DownloadsPage from 'pages/statements/DownloadsPage';
import EntranceExamsPage from 'pages/statements/EntranceExamsPage';
import FillingStatementsPage from 'pages/statements/FillingStatementsPage';
import LevelPage from 'pages/statements/LevelPage';
import PriorityRightsPage from 'pages/statements/PriorityRightsPage';
import PrivilegesPage from 'pages/statements/PrivilegesPage';
import ResultsExamPage from 'pages/statements/ResultsExamPage';
import ApplicationIdPage from 'pages/statements/StatementIdPage';
import StatementsListPage from 'pages/statements/StatementsListPage';
import StatementsPage from 'pages/statements/StatementsPage';
import TargetAgreementPage from 'pages/statements/TargetAgreementPage';
import TrajectoryPage from 'pages/statements/TrajectoryPage';
import ListStudents from 'pages/students/ListStudents';
import ViewerLayout from 'pages/ViewerLayout';

import ExpertExaminationResultPage from './pages/expert/ExpertExaminationResultPage';
import ModeratorApplicationDuplicatesPage from './pages/moderator/ModeratorApplicationDuplicatesPage';
import ModeratorEpguInfoPage from './pages/moderator/ModeratorEpguInfoPage';
import ModeratorHallmarksPage from './pages/moderator/ModeratorHallmarksPage';
import ModeratorTargetContractPage from './pages/moderator/ModeratorTargetContractPage';
import EducationalInfoPage from './pages/statements/EducationalInfoPage';

const admissionCampaignPathsToEducationLevels: {
  [key: string]: ApplicationEducationLevel;
} = {
  'bachelor-specialist': ApplicationEducationLevel.BACHELOR,
  postgraduate: ApplicationEducationLevel.GRADUATE,
  master: ApplicationEducationLevel.MASTER,
  spo: ApplicationEducationLevel.SECONDARY,
  preparatory: ApplicationEducationLevel.PREPARATORY,
};

const AdmissionCampaignRoutes = (
  <Route path="applicants">
    <Route index element={<AdmissionCampaignPage />} />
    {Object.keys(admissionCampaignPathsToEducationLevels).map(path => {
      const educationLevel = admissionCampaignPathsToEducationLevels[path];
      return (
        <>
          <Route
            path={path}
            element={<EnrollListsPage educationLevel={educationLevel} />}
          />
          <Route
            path={`${path}/rating`}
            element={<EnrollmentRatingsPage educationLevel={educationLevel} />}
          />
        </>
      );
    })}
  </Route>
);

const Routing = () => (
  <Routes>
    <Route path="/enroll-lists">
      <Route index element={<Navigate to={'bachelor-specialist'} />} />
      <Route
        path="bachelor-specialist"
        element={
          <LegacyEnrollListsPage
            educationLevel={ApplicationEducationLevel.BACHELOR}
          />
        }
      />
      <Route
        path="postgraduate"
        element={
          <LegacyEnrollListsPage
            educationLevel={ApplicationEducationLevel.GRADUATE}
          />
        }
      />
      <Route
        path="master"
        element={
          <LegacyEnrollListsPage
            educationLevel={ApplicationEducationLevel.MASTER}
          />
        }
      />
      <Route
        path="spo"
        element={
          <LegacyEnrollListsPage
            educationLevel={ApplicationEducationLevel.SECONDARY}
          />
        }
      />
      <Route
        path="preparatory"
        element={
          <LegacyEnrollListsPage
            educationLevel={ApplicationEducationLevel.PREPARATORY}
          />
        }
      />
    </Route>

    <Route path="/sign-up" element={<SignUpPage />} />
    <Route path="/confirm-account" element={<ConfirmAccountPage />} />
    <Route path="/reset-password" element={<ResetPasswordPage />} />
    <Route path="/sign-in" element={<SignInPage />} />

    <Route path="legacy/applicants">
      <Route index element={<Navigate to={'bachelor-specialist'} />} />
      <Route
        path="bachelor-specialist"
        element={
          <>
            <Navbar isStaff={false} />
            <LegacyEnrollListsPage
              educationLevel={ApplicationEducationLevel.BACHELOR}
            />
          </>
        }
      />
      <Route
        path="postgraduate"
        element={
          <>
            <Navbar isStaff={false} />
            <LegacyEnrollListsPage
              educationLevel={ApplicationEducationLevel.GRADUATE}
            />
          </>
        }
      />
      <Route
        path="master"
        element={
          <>
            <Navbar isStaff={false} />
            <LegacyEnrollListsPage
              educationLevel={ApplicationEducationLevel.MASTER}
            />
          </>
        }
      />
      <Route
        path="spo"
        element={
          <>
            <Navbar isStaff={false} />
            <LegacyEnrollListsPage
              educationLevel={ApplicationEducationLevel.SECONDARY}
            />
          </>
        }
      />
      <Route
        path="preparatory"
        element={
          <>
            <Navbar isStaff={false} />
            <LegacyEnrollListsPage
              educationLevel={ApplicationEducationLevel.PREPARATORY}
            />
          </>
        }
      />
    </Route>

    {AdmissionCampaignRoutes}

    <Route
      path="/applicants/:scenario"
      element={
        <>
          <Navbar isStaff={false} />
          <ListStudents />
        </>
      }
    />
    <Route
      path="/applicants/competitive/:scenario"
      element={
        <>
          <Navbar isStaff={false} />
          <ListStudents />
        </>
      }
    />

    <Route
      path="/"
      element={
        <RequireAuth>
          <ViewerLayout isStaff={false} />
        </RequireAuth>
      }
    >
      <Route index element={<Navigate to="personal-information" />} />
      <Route path="personal-information" element={<ProfilePage />}>
        <Route index element={<Navigate to="basic-data" />} />
        <Route path="basic-data" element={<PersonalDataPage />} />
        <Route
          path="legal-representative"
          element={<LegalRepresentativePage />}
        />
        <Route path="passport-data" element={<PassportDataPage />} />
        <Route path="visa" element={<VisaPage />} />
        <Route path="compatriot-data" element={<CompatriotDataPage />} />
        <Route path="address" element={<ResidencePage />} />
        <Route path="educational-background" element={<EducationDataPage />} />
      </Route>

      <Route path="application" element={<StatementsPage />}>
        <Route index element={<Navigate to="create" />} />
        <Route path="create" element={<StatementsListPage />} />
        <Route path="trajectory" element={<TrajectoryPage />} />
        <Route path="training-level" element={<LevelPage />} />
        <Route path="directions-type" element={<DirectionsTypePage />} />
        <Route path="educational-documents" element={<EducationalInfoPage />} />
        <Route path=":applicationId" element={<ApplicationIdPage />}>
          <Route index element={<Navigate to="filling-statements" />} />
          <Route path="filling-statements" element={<FillingStatementsPage />}>
            <Route index element={<Navigate to="exam-results" />} />
            <Route path="exam-results" element={<ResultsExamPage />} />
            <Route path="directions" element={<DirectionsTrainingPage />}>
              <Route path="" element={<DirectionsPage />} />
              <Route path="create" element={<DirectionsTrainingCreatePage />} />
            </Route>
            <Route path="achievements" element={<AchievementsPage />} />
            <Route path="privileges" element={<PrivilegesPage />} />
            <Route path="priority-rights" element={<PriorityRightsPage />} />
            <Route path="target" element={<TargetAgreementPage />} />
            <Route path="contract">
              <Route path="" element={<ContractPage />} />
              <Route path="create" element={<CreateContractPage />} />
              <Route path="edit/:contractId" element={<CreateContractPage />} />
            </Route>
            <Route
              path="agreement"
              element={<SubmitDirectionAgreementForm />}
            />
            <Route path="blank" element={<DownloadsPage />} />
            <Route path="test" element={<EntranceExamsPage />} />
          </Route>
        </Route>
      </Route>
      <Route path="additional-files" element={<AdditionalFilesPage />} />
    </Route>

    <Route path="/admin/sign-in" element={<ModeratorSignInPage />} />
    <Route
      path="/admin"
      element={
        <RequireStaffRole>
          <ViewerLayout isStaff={true} />
        </RequireStaffRole>
      }
    >
      <Route
        path="personal-information"
        element={<ModeratorPersonalInformationPage />}
      />
      <Route
        path="personal-information/:userId"
        element={<ModeratorPersonalInformationIdPage />}
      >
        <Route index element={<Navigate to="basic-data" />} />
        <Route path="basic-data" element={<SupportBasicDataPage />} />
        <Route
          path="legal-representative"
          element={<ModeratorLegalRepresentativePage isSupport />}
        />
        <Route
          path="passport-data"
          element={<ModeratorPassportDataPage isSupport />}
        />
        <Route path="visa" element={<ModeratorVisaPage isSupport />} />
        <Route
          path="actual-address"
          element={<ModeratorActualAddressPage isSupport />}
        />
        <Route
          path="registration-address"
          element={<ModeratorRegistrationAddressPage isSupport />}
        />
        <Route
          path="educational-background"
          element={<ModeratorEducationPage isSupport />}
        />
        <Route
          path="documents"
          element={<ModeratorDocumentsPage isSupport />}
        />
      </Route>

      <Route path="statements" element={<ModeratorStatementsPage />} />
      <Route path="statements/:id" element={<ModeratorStatementsIdPage />}>
        <Route index element={<Navigate to="basic-data" />} />
        <Route path="basic-data" element={<ModeratorBasicDataPage />} />
        <Route
          path="legal-representative"
          element={<ModeratorLegalRepresentativePage />}
        />
        <Route path="passport-data" element={<ModeratorPassportDataPage />} />
        <Route path="visa" element={<ModeratorVisaPage />} />
        <Route path="actual-address" element={<ModeratorActualAddressPage />} />
        <Route
          path="registration-address"
          element={<ModeratorRegistrationAddressPage />}
        />
        <Route
          path="educational-background"
          element={<ModeratorEducationPage />}
        />
        <Route path="documents" element={<ModeratorDocumentsPage />} />
        <Route path="hallmarks" element={<ModeratorHallmarksPage />} />
        <Route path="exam-results" element={<ModeratorExamResultPage />} />
        <Route path="directions" element={<ModeratorDirectionsPage />} />
        <Route
          path="target-contract"
          element={<ModeratorTargetContractPage />}
        />
        <Route
          path="additional-forms"
          element={<ModeratorAdditionalFormsPage />}
        />
        <Route path="downloads" element={<ModeratorDownloadsPage />} />
        <Route
          path="expert-examination"
          element={<ExpertExaminationResultPage />}
        />
        <Route
          path="duplicates"
          element={<ModeratorApplicationDuplicatesPage />}
        />
        <Route path="epgu-info" element={<ModeratorEpguInfoPage />} />
        <Route path="1c" element={<Moderator1cPage />} />
      </Route>

      <Route path="contracts" element={<ModeratorContractPage />} />
      <Route path="contracts/:id" element={<ModeratorContractIdPage />} />

      <Route
        path="upload-queue"
        element={
          <AuthChecker
            allowedRoles={[
              UserRole.ADMIN,
              UserRole.MODERATOR,
              UserRole.INSTITUTE_STAFF,
              UserRole.SPECIALIST,
            ]}
          >
            <UploadQueuePage />
          </AuthChecker>
        }
      />
      <Route
        path="upload-queue/:userId/*"
        element={
          <AuthChecker
            allowedRoles={[
              UserRole.ADMIN,
              UserRole.MODERATOR,
              UserRole.INSTITUTE_STAFF,
              UserRole.SPECIALIST,
            ]}
          >
            <UploadQueueEntryPage />
          </AuthChecker>
        }
      />
      <Route
        path="registry"
        element={
          <AuthChecker allowedRoles={[UserRole.ADMIN]}>
            <RegistryPage />
          </AuthChecker>
        }
      />
      <Route
        path="registry/:id"
        element={
          <AuthChecker allowedRoles={[UserRole.ADMIN]}>
            <RegistryEntryPage />
          </AuthChecker>
        }
      />
      <Route path="legacy/applicants">
        <Route index element={<Navigate to={'bachelor-specialist'} />} />
        <Route
          path="bachelor-specialist"
          element={
            <LegacyEnrollListsPage
              educationLevel={ApplicationEducationLevel.BACHELOR}
            />
          }
        />
        <Route
          path="postgraduate"
          element={
            <LegacyEnrollListsPage
              educationLevel={ApplicationEducationLevel.GRADUATE}
            />
          }
        />
        <Route
          path="master"
          element={
            <LegacyEnrollListsPage
              educationLevel={ApplicationEducationLevel.MASTER}
            />
          }
        />
        <Route
          path="spo"
          element={
            <LegacyEnrollListsPage
              educationLevel={ApplicationEducationLevel.SECONDARY}
            />
          }
        />
        <Route
          path="preparatory"
          element={
            <LegacyEnrollListsPage
              educationLevel={ApplicationEducationLevel.PREPARATORY}
            />
          }
        />
      </Route>

      {AdmissionCampaignRoutes}

      <Route
        path="administration"
        element={
          <AuthChecker allowedRoles={[UserRole.ADMIN, UserRole.SPECIALIST]}>
            <AdministrationPage />
          </AuthChecker>
        }
      />
      <Route
        path="directory"
        element={
          <AuthChecker allowedRoles={[UserRole.ADMIN]}>
            <DirectoryPage />
          </AuthChecker>
        }
      />
      <Route
        path="directory/:directoryName-:directoryId"
        element={
          <AuthChecker allowedRoles={[UserRole.ADMIN]}>
            <DirectoryInfo />
          </AuthChecker>
        }
      />
      <Route
        path="audit"
        element={
          <AuthChecker allowedRoles={[UserRole.ADMIN]}>
            <LogPage />
          </AuthChecker>
        }
      />
      <Route path="users" element={<UsersPage />} />
      <Route path="document-templates" element={<DocumentTemplatesPage />} />
    </Route>

    <Route
      path="/admin/users/impersonate/:userId"
      element={
        <RequireStaffRole>
          <ViewerLayout isImpersonate />
        </RequireStaffRole>
      }
    >
      <Route index element={<Navigate to="info/personal-information" />} />
      <Route path="info/personal-information" element={<ProfilePage />}>
        <Route index element={<Navigate to="basic-data" />} />
        <Route path="basic-data" element={<PersonalDataPage />} />
        <Route path="compatriot-data" element={<CompatriotDataPage />} />
        <Route path="passport-data" element={<PassportDataPage />} />
        <Route path="educational-background" element={<EducationDataPage />} />
        <Route path="visa" element={<VisaPage />} />
        <Route path="address" element={<ResidencePage />} />
        <Route
          path="legal-representative"
          element={<LegalRepresentativePage />}
        />
      </Route>
    </Route>

    <Route
      path="/admin/users/impersonate/:userId/:applicationId"
      element={
        <RequireStaffRole>
          <ViewerLayout isImpersonate />
        </RequireStaffRole>
      }
    >
      <Route index element={<Navigate to="info/personal-information" />} />
      <Route path="info/personal-information" element={<ProfilePage />}>
        <Route index element={<Navigate to="basic-data" />} />
        <Route path="basic-data" element={<PersonalDataPage />} />
        <Route path="compatriot-data" element={<CompatriotDataPage />} />
        <Route path="passport-data" element={<PassportDataPage />} />
        <Route path="educational-background" element={<EducationDataPage />} />
        <Route path="visa" element={<VisaPage />} />
        <Route path="address" element={<ResidencePage />} />
        <Route
          path="legal-representative"
          element={<LegalRepresentativePage />}
        />
      </Route>
      <Route path="statement" element={<ApplicationIdPage />}>
        <Route index element={<Navigate to="filling-statements" />} />
        <Route path="filling-statements" element={<FillingStatementsPage />}>
          <Route index element={<Navigate to="exam-results" />} />
          <Route path="exam-results" element={<ResultsExamPage />} />
          <Route path="directions" element={<DirectionsTrainingPage />}>
            <Route path="" element={<DirectionsPage />} />
            <Route path="create" element={<DirectionsTrainingCreatePage />} />
          </Route>
          <Route path="achievements" element={<AchievementsPage />} />
          <Route path="privileges" element={<PrivilegesPage />} />
          <Route path="priority-rights" element={<PriorityRightsPage />} />
          <Route path="target" element={<TargetAgreementPage />} />
          <Route path="contract">
            <Route path="" element={<ContractPage />} />
            <Route path="create" element={<CreateContractPage />} />
            <Route path="edit/:contractId" element={<CreateContractPage />} />
          </Route>
          <Route path="agreement" element={<SubmitDirectionAgreementForm />} />
          <Route path="blank" element={<DownloadsPage />} />
          <Route path="test" element={<EntranceExamsPage />} />
        </Route>
      </Route>

      <Route path="additional-files" element={<AdditionalFilesPage />} />
    </Route>
    <Route path="*" element={<NotFound404 />} />
  </Routes>
);

export default Routing;
