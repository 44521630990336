import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import Button from 'components/common/ui-kit/Button';
import Checkbox from 'components/common/ui-kit/Checkbox';
import Input from 'components/common/ui-kit/Input';
import ReactSelect from 'components/common/ui-kit/Select';
import { DadataAddressAutocomplete } from 'components/profile/AddressesForm/dadata';
import ProfileSection from 'components/profile/ProfileSection';
import FillingStatementsForm from 'components/statements/FillingStatementsForm';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import { DadataCountryIsoCode } from 'models/questionnaire/dadata';

import { useGetIdentificationDocumentTypeOptions } from '../../../../hooks/questionnaire/passportDraft/useGetIdentificationDocumentTypeOptions';
import useNotEntrantsContractForm from '../../../../hooks/statements/contract/useNotEntrantsContractForm';
import { Contract } from '../../../../models/applications/contracts';
import LazyFileUploader from '../../../common/ui-kit/LazyFileUploader';

type NotEntrantsContractFormProps = {
  readonly contract?: Contract;
  readonly isFillingStatementsForm?: boolean;
  onSubmit?: (res) => void;
};

const NotEntrantsContractForm: FC<NotEntrantsContractFormProps> = ({
  contract,
  isFillingStatementsForm = true,
  onSubmit: onSubmitProp,
}) => {
  const {
    fields,
    errors,
    control,
    fileProps,
    onSubmit,
    directionOptions,
    paymentForms,
    isSeriesRequired,
    isDateOfExpirationExists,
  } = useNotEntrantsContractForm(
    contract,
    isFillingStatementsForm,
    onSubmitProp
  );

  const identificationDocumentTypeOptions =
    useGetIdentificationDocumentTypeOptions();

  const formBody = (
    <>
      <ProfileSection title="">
        <div className="profile-form__fields">
          <Controller
            name={fields.directionId.name}
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={directionOptions}
                placeholder={terms.DIRECTIONS_TRAINING}
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                error={errors.directionId?.message}
                isDisabled={Boolean(contract)}
              />
            )}
          />

          <Controller
            name={fields.paymentForm.name}
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={paymentForms}
                placeholder={terms.CONTRACT_PAYMENT_TYPE}
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                error={errors.paymentForm?.message}
              />
            )}
          />
        </div>
      </ProfileSection>

      <ProfileSection title={terms.PAYER_INFORMATION}>
        <div className="profile-form__fields">
          <Input
            className="profile-form__field"
            placeholder={terms.SURNAME_IN_RUSSIAN}
            markRequired={true}
            {...fields.lastName}
            error={errors.lastName?.message}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.NAME_IN_RUSSIAN}
            markRequired={true}
            {...fields.firstName}
            error={errors.firstName?.message}
          />
          <Input
            placeholder={terms.PATRONYMIC}
            hint={terms.CYRILLIC_CHARACTERS_ONLY}
            className="profile-form__field"
            {...fields.middleName}
            error={errors.middleName?.message}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.DATE_OF_BIRTH}
            type="date"
            max="100"
            markRequired={true}
            {...fields.birthDate}
            error={errors.birthDate?.message}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.MOBILE_PHONE}
            type="tel"
            markRequired={true}
            {...fields.phone}
            error={errors.phone?.message}
          />
          <Controller
            name="addressRegistration"
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            control={control}
            render={({ field: { onChange, value, disabled } }) => (
              <DadataAddressAutocomplete
                className="profile-form__field profile-form__field--fb-100"
                placeholder={terms.REGISTRATION_ADDRESS}
                countryIsoCode={DadataCountryIsoCode.ALL}
                value={null}
                onSelect={option => {
                  onChange(option?.label);
                }}
                onInputValueChange={value => {
                  onChange(value);
                }}
                inputValue={value ?? ''}
                isDisabled={disabled}
                markRequired
                error={errors['addressRegistration']?.message as string}
              />
            )}
          />
          <Controller
            name="addressResidential"
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            control={control}
            render={({ field: { onChange, value, disabled } }) => (
              <DadataAddressAutocomplete
                className="profile-form__field profile-form__field--fb-100"
                placeholder={terms.ACTUAL_ADDRESS}
                countryIsoCode={DadataCountryIsoCode.ALL}
                value={null}
                onSelect={option => {
                  onChange(option?.label);
                }}
                onInputValueChange={value => {
                  onChange(value);
                }}
                inputValue={value ?? ''}
                isDisabled={disabled}
                markRequired
                error={errors['addressResidential']?.message as string}
              />
            )}
          />
        </div>
      </ProfileSection>

      <ProfileSection title={terms.PAYER_PASSPORT}>
        <div className="profile-form__fields">
          <Controller
            name="docType"
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                className="profile-form__field"
                {...(field as object)}
                options={identificationDocumentTypeOptions.options}
                placeholder={terms.DOCUMENT_TYPE}
                error={errors.docType?.message}
                markRequired={true}
              />
            )}
          />
          <div className="profile-form__field profile-form__field--container">
            <Input
              className="profile-form__input profile-form__input--fb-50"
              placeholder={terms.SERIES}
              markRequired={isSeriesRequired}
              {...fields.series}
              error={errors.series?.message}
            />
            <Input
              className="profile-form__input profile-form__input--fb-50"
              placeholder={terms.NUMBER}
              markRequired={true}
              {...fields.number}
              error={errors.number?.message}
            />
          </div>
          <Input
            className="profile-form__field"
            placeholder={terms.DIVISION_CODE}
            markRequired={true}
            {...fields.divisionCode}
            error={errors.divisionCode?.message}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.DATE_OF_ISSUE}
            type="date"
            markRequired={true}
            {...fields.issuedAt}
            error={errors.issuedAt?.message}
          />
          {isDateOfExpirationExists && (
            <Input
              className="profile-form__field"
              placeholder={terms.DATE_OF_EXPIRATION}
              type="date"
              markRequired={true}
              {...fields.docExpirationDate}
              error={errors.docExpirationDate?.message}
            />
          )}
          <Input
            className="profile-form__field"
            placeholder={terms.ISSUING_AUTHORITY}
            markRequired={true}
            {...fields.issuedBy}
            error={errors.issuedBy?.message}
          />
          <Input
            className="profile-form__field profile-form__field--fb-100"
            placeholder={terms.PLACE_OF_BIRTH}
            markRequired={true}
            {...fields.placeOfBirth}
            error={errors.placeOfBirth?.message}
          />
          <div>
            <Checkbox
              text="Есть доверенность представителя"
              className="profile-form__checkbox"
              {...fields.attorneyRepresentative}
            />
            <Checkbox
              text="Есть доверенность заказчика"
              className="profile-form__checkbox"
              {...fields.attorneyCustomer}
            />
          </div>
        </div>
      </ProfileSection>

      <ProfileSection title={terms.COPY_OF_PASSPORT} markRequired={true}>
        <LazyFileUploader
          className="profile-form__file-uploader"
          {...fileProps}
        />
        {errors.wasDocumentAttached && (
          <div className="profile-form__error">
            {errors.wasDocumentAttached?.message}
          </div>
        )}
      </ProfileSection>
      <Button className="create-contract-form__button" theme="success">
        {contract ? 'Редактировать' : terms.CREATE_CONTRACT}
      </Button>
    </>
  );

  if (isFillingStatementsForm) {
    return (
      <FillingStatementsForm
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {formBody}
      </FillingStatementsForm>
    );
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {formBody}
    </form>
  );
};

export default NotEntrantsContractForm;
