import React, { FC, useMemo, useState } from 'react';

import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import terms, { isRussianLocale } from 'i18n';

import useAppDispatch from '../../../hooks/common/useAppDispatch';
import useModeratorDirectionElementForm from '../../../hooks/moderator/applications/useModeratorDirectionElementForm';
import { ApplicationByStaff } from '../../../models/applications/applications';
import { ChosenDirectionWithPriority } from '../../../models/applications/directions';
import {
  ApplicationEducationLevel,
  ApplicationState,
  DirectionApplicationEducationLevel,
  Trajectory,
} from '../../../models/applications/enum';
import { SelectOption, translate } from '../../../models/common';
import { createToast } from '../../../redux/actions';
import { useGetDesiredDirectionsQuery } from '../../../redux/api/applications/applications-api';
import {
  useGetAvailablePrivilegesQuery,
  useSetPrivilegeToDirectionMutation,
} from '../../../redux/api/applications/directions-api';
import { selectCurrentApplication } from '../../../redux/selectors/staff/applications';
import { formatDateTimeToRuFormat } from '../../../utils/date';
import { createInputV2Props } from '../../../utils/form-helpers';
import Button from '../../common/ui-kit/Button';
import Checkbox from '../../common/ui-kit/Checkbox';
import Input from '../../common/ui-kit/Input';
import ReactSelect from '../../common/ui-kit/Select';

const PREPARATORY_EDUCATION_LEVEL_ID = 4;

type MasterLocalPrivilegeFormTypes = {
  usePrivilege: boolean;
  privilegeId: SelectOption;
};

const MasterLocalPrivilegeForm = ({
  applicationDirectionId,
  selectedPrivilegeId,
  applicationId,
}: {
  applicationDirectionId: number;
  selectedPrivilegeId?: number;
  applicationId: number;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, setValue, watch } =
    useForm<MasterLocalPrivilegeFormTypes>({
      defaultValues: {
        usePrivilege: !!selectedPrivilegeId,
      },
    });

  const [setPrivilegeToDirection] = useSetPrivilegeToDirectionMutation();

  const { data: privileges } = useGetAvailablePrivilegesQuery(
    applicationDirectionId,
    { skip: !applicationId || !applicationDirectionId }
  );

  const { privilegeOptions, selectedPrivilege } = useMemo(() => {
    let selectedPrivilege;
    const privilegeOptions =
      privileges && privileges.length > 0
        ? privileges.map?.(el => {
            if (el.id === selectedPrivilegeId)
              selectedPrivilege = {
                id: el.id,
                value: el.id,
                label: el.privilegeTypeResponse.title,
              };
            return {
              id: el.id,
              value: el.id,
              label: el.privilegeTypeResponse.title,
            };
          })
        : undefined;
    return { privilegeOptions, selectedPrivilege };
  }, [privileges]);

  const handleFormSubmit = handleSubmit(data => {
    setPrivilegeToDirection({
      applicationDirectionId,
      privilegeId: data.privilegeId?.value,
    })
      .unwrap()
      .then(direction => {
        setValue('privilegeId', {
          value: direction.selectedPrivilege?.id,
          label: direction.selectedPrivilege?.privilegeTypeResponse.title,
        });
        dispatch(createToast('Информация сохранена', 'success'));
      })
      .catch(() => {
        dispatch(createToast('Во время сохранения произошла ошибка', 'danger'));
      });
  });

  const usePrivilege = watch('usePrivilege');

  return (
    <form onSubmit={handleFormSubmit}>
      <Controller
        name="usePrivilege"
        control={control}
        render={({ field }) => (
          <Checkbox
            text={
              'Поступает на основании Конкурса портфолио / Олимпиады "Я-профессионал"'
            }
            checked={field.value}
            onChange={field.onChange}
            disabled={!privilegeOptions}
          />
        )}
      />
      <div className="profile-form__fields">
        <Controller
          name="privilegeId"
          rules={{
            required: usePrivilege,
          }}
          control={control}
          render={({ field }) => (
            <ReactSelect
              placeholder={'Выберите отличительный признак'}
              className="profile-form__field"
              markRequired={true}
              {...field}
              options={privilegeOptions ?? []}
              value={field.value ?? selectedPrivilege}
              isDisabled={!usePrivilege}
            />
          )}
        />

        <Button
          type="submit"
          theme="success"
          // disabled={!usePrivilege}
          style={{ alignSelf: 'center' }}
        >
          Сохранить
        </Button>
      </div>
    </form>
  );
};

type ModeratorDirectionsElementProps = {
  readonly direction: ChosenDirectionWithPriority;
};

const ModeratorDirectionsElement: FC<ModeratorDirectionsElementProps> = ({
  direction: {
    direction: {
      code,
      title,
      entranceTestsSets,
      paymentForm,
      educationLevel,
      educationForm,
      educationProgram,
      faculty,
      asBenefit,
      asQuota,
      applicationEducationLevel,
      budgetLevel,
      targetOrganization,
      educationLanguageName,
    },
    priority,
    createdAt,
    deletedAt,
    refuseEnrollmentDate,
    id: applicationDirectionId,
    profileSubjectScore,
    rtTargetAgreeStatus,
    rtTargetAgreeCreatedAt,
    selectedPrivilege,
    admitted,
    entranceTestsSatisfied,
    privilegeAdmitted,
    targetContractPresent,
  },
}) => {
  const [open, setOpen] = useState(false);

  const currentApplication: ApplicationByStaff | undefined = useSelector(
    selectCurrentApplication
  );

  const {
    fields,
    control,
    formState: { errors },
  } = useModeratorDirectionElementForm(
    applicationDirectionId,
    profileSubjectScore
  );

  const inputV2Props = createInputV2Props(errors, false);

  const { data: allegedEducationTrack } = useGetDesiredDirectionsQuery(
    {
      applicationId: currentApplication?.id ?? 0,
    },
    { skip: educationLevel.id !== PREPARATORY_EDUCATION_LEVEL_ID }
  );

  return (
    <div
      className="moderator-directions-element"
      style={admitted ? {} : { border: '1px solid red', background: '#fff6f6' }}
    >
      <div
        className="moderator-directions-element__header"
        onClick={() => setOpen(!open)}
      >
        <h2>
          {code +
            ' ' +
            title +
            (targetOrganization ? ' ' + targetOrganization.shortName : '')}
        </h2>
        <div className="moderator-directions-element__header-statuses">
          {asBenefit && <div>{terms.LGOT}</div>}

          {asQuota && <div>{terms.INDIVIDUAL_QUOTA}</div>}

          {applicationEducationLevel ===
            DirectionApplicationEducationLevel.SECONDARY && (
            <div>{terms.GRADE_9}</div>
          )}

          {applicationEducationLevel ===
            DirectionApplicationEducationLevel.UPPER_SECONDARY && (
            <div>{terms.GRADE_11}</div>
          )}

          <div
            className={cn('moderator-directions-element__header-status', {
              'moderator-directions-element__header-status-deleted': true,
            })}
          >
            {deletedAt ? terms.REMOVED : null}
          </div>
        </div>
      </div>

      {open && (
        <div className="moderator-directions-element__body">
          <div className="moderator-data-list">
            <div className="moderator-data-list__element">
              <p>{terms.DISCIPLINES}:</p>
              <ul>
                {entranceTestsSets
                  .map(sets => sets.subjects)
                  .flat(1)
                  .filter(
                    (value, index, array) =>
                      array.findIndex(v => v.id === value.id) === index
                  )
                  .map((test, index) => (
                    <li key={index}>
                      <span>{test.title}</span>
                      <br />
                      {/* <span>{test.titleForeign}</span>*/}
                    </li>
                  ))}
              </ul>
            </div>

            {/* {benefits && (
              <div className="moderator-data-list__element">
                <p>{terms.LGOT}</p>
              </div>
            )}*/}

            <div className="moderator-data-list__element">
              <p>{terms.SOURCE_OF_FINANCING}:</p>
              <span>
                {isRussianLocale()
                  ? paymentForm.title
                  : paymentForm.titleForeign}
              </span>
            </div>

            <div className="moderator-data-list__element">
              <p>Статус заявки на портале Работа в России:</p>
              <span>
                {rtTargetAgreeStatus ? terms[rtTargetAgreeStatus] : '-'}
              </span>
            </div>

            <div className="moderator-data-list__element">
              <p>Дата создания заявки на портале Работа в России:</p>
              <span>
                {rtTargetAgreeCreatedAt
                  ? formatDateTimeToRuFormat(rtTargetAgreeCreatedAt)
                  : '-'}
              </span>
            </div>

            {budgetLevel && (
              <div className="moderator-data-list__element">
                <p>{terms.BUDGET_LEVEL}</p>
                <span>{budgetLevel ?? '-'}</span>
              </div>
            )}

            <div className="moderator-data-list__element">
              <p>{terms.EDUCATION_LANGUAGE}:</p>
              <span>{educationLanguageName}</span>
            </div>

            {targetOrganization && (
              <div className="moderator-data-list__element">
                <p>{terms.TARGET_ORGANIZATION}:</p>
                <div>
                  <div className="moderator-data-list__subelement">
                    <p>{terms.TARGET_ORGANIZATION_NAME}:</p>
                    <span>
                      {targetOrganization.name ? targetOrganization.name : '-'}
                    </span>
                  </div>
                  <div className="moderator-data-list__subelement">
                    <p>{terms.TARGET_ORGANIZATION_SHORTNAME}:</p>
                    <span>
                      {targetOrganization.shortName
                        ? targetOrganization.shortName
                        : '-'}
                    </span>
                  </div>
                  <div className="moderator-data-list__subelement">
                    <p>{terms.INN}:</p>
                    <span>
                      {targetOrganization.inn ? targetOrganization.inn : '-'}
                    </span>
                  </div>
                  <div className="moderator-data-list__subelement">
                    <p>{terms.KPP}:</p>
                    <span>
                      {targetOrganization.kpp ? targetOrganization.kpp : '-'}
                    </span>
                  </div>
                  <div className="moderator-data-list__subelement">
                    <p>{terms.OGRN}:</p>
                    <span>
                      {targetOrganization.ogrn ? targetOrganization.ogrn : '-'}
                    </span>
                  </div>
                  <div className="moderator-data-list__subelement">
                    <p>{terms.TARGET_ORGANIZATION_ADDRESS}:</p>
                    <span>
                      {targetOrganization.address
                        ? targetOrganization.address
                        : '-'}
                    </span>
                  </div>
                  <div className="moderator-data-list__subelement">
                    <p>{terms.TARGET_ORGANIZATION_PHONE}:</p>
                    <span>
                      {targetOrganization.telephone
                        ? targetOrganization.telephone
                        : '-'}
                    </span>
                  </div>
                  <div className="moderator-data-list__subelement">
                    <p>{terms.TARGET_ORGANIZATION_EMAIL}:</p>
                    <span>
                      {targetOrganization.email
                        ? targetOrganization.email
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div className="moderator-data-list__element">
              <p>{terms.FORM_OF_STUDY}:</p>
              <span>{educationForm.title || '-'}</span>
            </div>

            <div className="moderator-data-list__element">
              <p>{terms.THE_LEVEL_OF_EDUCTION}:</p>
              <span>{educationLevel.title || '-'}</span>
            </div>

            <div className="moderator-data-list__element">
              <p>Приоритет:</p>
              <span>{priority || '-'}</span>
            </div>

            {educationProgram && (
              <div className="moderator-data-list__element">
                <p>Образовательная программа:</p>
                <span>
                  {`${educationProgram.title} ${educationProgram.code}` || '-'}
                </span>
              </div>
            )}

            <div className="moderator-data-list__element">
              <p>{terms.SUBDIVISION}:</p>
              <span>{translate(faculty)}</span>
            </div>

            {educationLevel.id === 4 && allegedEducationTrack && (
              <>
                <b>Планирует поступить на:</b>
                <div className="moderator-data-list__element">
                  <p>{terms.STUDY_FIELD}:</p>
                  <span>{allegedEducationTrack.desiredDirection || ''}</span>
                </div>
                <div className="moderator-data-list__element">
                  <p>{terms.THE_LEVEL_OF_EDUCTION}:</p>
                  <span>
                    {allegedEducationTrack.educationLevel.title || ''}
                  </span>
                </div>
              </>
            )}

            <div className="moderator-data-list__element">
              <p>{terms.CREATION_DATE}:</p>
              <span>
                {createdAt ? formatDateTimeToRuFormat(createdAt) : '-'}
              </span>
            </div>

            <div className="moderator-data-list__element">
              <p>{terms.DELETION_DATE}:</p>
              <span>
                {deletedAt ? formatDateTimeToRuFormat(deletedAt) : '-'}
              </span>
            </div>

            <div className="moderator-data-list__element">
              <p>{terms.REFUSE_ENROLLMENT_DATE}:</p>
              <span>
                {refuseEnrollmentDate
                  ? formatDateTimeToRuFormat(refuseEnrollmentDate)
                  : '-'}
              </span>
            </div>

            {currentApplication?.state === ApplicationState.ALLOWED && (
              <>
                <div className="moderator-data-list__element">
                  <p>{terms.ADMITTED}:</p>
                  <span style={admitted ? {} : { color: 'red' }}>
                    {admitted ? terms.YES : terms.NO}
                  </span>
                </div>

                {!entranceTestsSatisfied && (
                  <div className="moderator-data-list__element">
                    <p>{terms.ENTRANCE_TESTS_SATISFIED}:</p>
                    <span>{entranceTestsSatisfied ? terms.YES : terms.NO}</span>
                  </div>
                )}

                {!privilegeAdmitted && (
                  <div className="moderator-data-list__element">
                    <p>{terms.PRIVILEGE_ADMITTED}:</p>
                    <span>{privilegeAdmitted ? terms.YES : terms.NO}</span>
                  </div>
                )}

                {!targetContractPresent && (
                  <div className="moderator-data-list__element">
                    <p>{terms.TARGET_CONTRACT_PRESENT}:</p>
                    <span>{targetContractPresent ? terms.YES : terms.NO}</span>
                  </div>
                )}
              </>
            )}
          </div>

          {educationLevel.title === 'СПО' && (
            <Controller
              name={fields.profileSubjectScore.name}
              control={control}
              render={({ field }) => (
                <Input
                  {...inputV2Props(field)}
                  // type="number"
                  className="moderator-directions-element__body-input"
                  placeholder={terms.PROFILE_SCORE}
                />
              )}
            />
          )}
          {/* {enrollmentGeneratedAt && (
            <table className="moderator-directions-element__body-table">
              <thead>
                <tr>
                  {headers.map(header => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{`${terms.CONSENT_TO_ENROLLMENT} ${
                    enrollmentGeneratedAt
                      ? formatDateTimeToRuFormat(enrollmentGeneratedAt)
                      : ''
                  }`}</td>
                  <td>{enrollmentAccepted ? 'Подано' : 'Отозвано'}</td>
                  <td>{!enrollmentAccepted ? enrollmentUpdatedAt : '-'}</td>
                </tr>
              </tbody>
            </table>
          )}*/}
          {currentApplication?.educationLevel ===
            ApplicationEducationLevel.MASTER &&
            currentApplication?.trajectory === Trajectory.LOCAL && (
              <MasterLocalPrivilegeForm
                applicationDirectionId={applicationDirectionId}
                selectedPrivilegeId={selectedPrivilege?.id}
                applicationId={currentApplication?.id ?? 0}
              />
            )}
        </div>
      )}
    </div>
  );
};

export default ModeratorDirectionsElement;
