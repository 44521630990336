import { DateString } from '../common';
import { SubjectResponse } from './school-tests';

export enum EntranceTestStatus {
  NOT_STARTED = 'NOT_STARTED',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
}

export type EntranceTest = {
  readonly id: number;
  readonly applicationId: number;
  readonly subject: SubjectResponse;
  readonly datetime?: DateString;
  readonly scheduleId?: number;
  readonly score: number;
  readonly moodleLink?: string;
  readonly status: EntranceTestStatus;
  readonly statementId?: number;
  readonly setByModer: boolean;
  readonly testEnded: boolean;
};

export type EntranceTestTime = {
  readonly hour: number;
  readonly minute: number;
  readonly second: number;
  readonly nano: number;
};

export type EntranceTestDate = {
  readonly id: number;
  readonly subjectId: number;
  readonly allowed: boolean;
  readonly testDate: DateString;
  readonly testStart: EntranceTestTime;
  readonly testStartDateTime: DateString;
  readonly testEnd: EntranceTestTime;
  readonly testEndDateTime: DateString;
  readonly checkinDate: DateString;
  readonly checkinTime: EntranceTestTime;
};
