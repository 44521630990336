import React, { useState } from 'react';

import AdminFilter from 'components/admin/AdminFilter/view';
import { defaultMapFiltersToRequest } from 'components/admin/AdminTableListPage/view';
import { userManagementApi } from 'redux/api/staff/admin/user-management-api';

import AdminUserListElement from '../../../components/admin/AdminUserListElement';
import Pagination from '../../../components/common/ui-kit/Pagination';
import {
  defaultUsersFilters,
  mapUserFiltersToRequest,
  renderUserAdministrationFilters,
} from '../AdministrationPage/view';

const UsersPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [filters, setFilters] = useState({});

  const onFiltersReset = () => {
    setCurrentPage(1);
    setFilters({});
  };

  const onFiltersSubmit = filters => {
    setCurrentPage(1);
    setFilters(filters);
  };

  const { data } = userManagementApi.useGetAllUsersByAdminQuery({
    request: mapUserFiltersToRequest(filters, defaultMapFiltersToRequest),
    page: currentPage,
    size: perPage,
  });

  return (
    <main className="moderator-contract-page">
      <h1 className="moderator-contract-page__title">Все пользователи</h1>
      {/* <AccordionTab title="Фильтры">
        <AdminUserListFilter />
      </AccordionTab> */}
      <AdminFilter
        defaultValues={defaultUsersFilters}
        filter={filters}
        onSubmit={onFiltersSubmit}
        onReset={onFiltersReset}
        displayCount={data?.totalElements}
        displayCountLabel="Пользователей"
      >
        {renderUserAdministrationFilters}
      </AdminFilter>
      {data?.result.map((item, index) => (
        <AdminUserListElement user={item} key={index} />
      ))}
      <Pagination
        lastPage={data?.totalPages ?? 0}
        currentPage={currentPage}
        perPage={perPage}
        onPageChange={value => setCurrentPage(value)}
        onPerPageChange={value => setPerPage(value)}
      />
    </main>
  );
};

export default UsersPage;
