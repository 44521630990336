import React, { FC, Fragment } from 'react';

import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Sorting,
  SortingOptions,
  SortingType,
} from 'components/admin/AdminTableListPage/view';
import Button from 'components/common/ui-kit/Button';
import Pagination, {
  PaginationProps,
} from 'components/common/ui-kit/Pagination/view';

import terms from '../../../i18n';
import { formatDateTimeToRuFormat } from '../../../utils/date';

const renderCell = (column, item, index, totalIndex) => {
  const render = column.render;
  if (render) {
    return render(item, index, totalIndex);
  }
  const accessor = column.accessor;
  const value = item[accessor];

  if (typeof value === 'boolean') {
    return value ? terms.YES : terms.NO;
  }

  if (!value) return '-';
  if (
    accessor === 'createdAt' ||
    accessor === 'updatedAt' ||
    accessor === 'lastUpdateAt'
  ) {
    return formatDateTimeToRuFormat(value);
  }

  return terms[value] !== undefined ? terms[value] : value;
};

type TableListProps = {
  readonly mappedData: any[];
  readonly columns: any[];
  readonly shouldOpenInNewTab?: boolean;
  readonly showCsvDownloadButton?: boolean;
  readonly onRowClick?: ((item) => void) | null;
  readonly pagination?: PaginationProps;
  sorting?: Sorting;
  onSort?: (sorting?: Sorting) => void;
  sortingOptions?: SortingOptions;
};

const TableList: FC<TableListProps> = ({
  mappedData,
  columns,
  sorting,
  onSort,
  showCsvDownloadButton = true,
  onRowClick,
  pagination,
  sortingOptions,
  shouldOpenInNewTab = false,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const onRowClickDefault = item => {
    if (shouldOpenInNewTab) {
      window.open(`${pathname}/${item.id?.toString()}`, '_blank');
      return;
    }
    navigate(item.id?.toString());
  };

  return (
    <>
      <section className="table-list">
        <header>
          {sortingOptions && (
            <div className="table-list__sort">
              <p>Сортировать:</p>
              <button
                onClick={() => {
                  onSort?.(undefined);
                }}
                className={classnames(
                  'table-list__radio-btn',
                  sorting === undefined && 'active'
                )}
              >
                по умолчанию
              </button>
              {sortingOptions.map(({ value, label }) => (
                <Fragment key={value}>
                  <button
                    onClick={
                      onSort
                        ? () =>
                            onSort({
                              type: SortingType.ASC,
                              name: value,
                            })
                        : undefined
                    }
                    className={classnames(
                      'table-list__radio-btn',
                      sorting?.type === SortingType.ASC &&
                        sorting?.name === value &&
                        'active'
                    )}
                  >
                    {label} (возрастанию)
                  </button>
                  <button
                    onClick={
                      onSort
                        ? () =>
                            onSort({
                              type: SortingType.DESC,
                              name: value,
                            })
                        : undefined
                    }
                    className={classnames(
                      'table-list__radio-btn',
                      sorting?.type === SortingType.DESC &&
                        sorting?.name === value &&
                        'active'
                    )}
                  >
                    {label} (убыванию)
                  </button>
                </Fragment>
              ))}
            </div>
          )}
          {/* <Button onClick={() => downloadStatementReport(filterData, order)}>*/}
          {showCsvDownloadButton && <Button>Выгрузить в CSV</Button>}
        </header>
        <div className="table-list__main">
          <table className="table-list__body">
            <thead>
              <tr>
                {columns.map(column => (
                  <th key={column.accessor}>{column.Header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {mappedData.map((item, i) => (
                <tr
                  key={i}
                  onClick={
                    onRowClick
                      ? () => onRowClick(item)
                      : onRowClick !== null
                      ? () => onRowClickDefault(item)
                      : undefined
                  }
                >
                  {columns.map(column => (
                    <td
                      key={item.id + '.' + i + '.' + column.accessor}
                      style={
                        item.hasNotAdmittedDirections ? { color: 'red' } : {}
                      }
                    >
                      {renderCell(
                        column,
                        item,
                        i,
                        pagination
                          ? (pagination.currentPage - 1) * pagination.perPage +
                              i
                          : undefined
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
      {pagination && (
        <>
          <div style={{ height: '1rem' }}></div>
          <Pagination {...pagination} />
        </>
      )}
    </>
  );
};

export default TableList;
