const commonApplicationColumnsPart1 = [
  { Header: 'ID', accessor: 'prettyUserId' },
  { Header: 'ФИО', accessor: 'fullName' },
  { Header: 'Гражданство', accessor: 'nationality' },
  { Header: 'Модератор', accessor: 'moderator' },
];

const commonApplicationColumnsPart2 = [
  { Header: 'Сообщения', accessor: 'unreadMessages' },
  { Header: 'Статус заявления', accessor: 'stateText' },
  { Header: 'Номер заявления', accessor: 'formattedApplicationId' },
  { Header: 'Дата подачи', accessor: 'createdAt' },
  { Header: 'Изменено', accessor: 'updatedAt' },
  { Header: 'Партнер', accessor: 'recruiter' },
  { Header: 'Уровень обр-я', accessor: 'educationLevel' },
  { Header: 'Институт', accessor: 'faculty' },
  { Header: 'Направление', accessor: 'direction' },
  { Header: 'Программа', accessor: 'educationProgram' },
  { Header: 'Форма обучения', accessor: 'educationForm' },
  { Header: 'Ист.фин.', accessor: 'formPayment' },
  { Header: 'Статус ЦЭИДО', accessor: 'expertStatus' },
  { Header: 'Статус коорд.', accessor: 'coordinatorStatus' },
  { Header: 'Есть ошибки', accessor: 'undefinedFields', render: application => application.undefinedFields? 'Есть': "Нет" }
];

export const columnsStatements = [
  ...commonApplicationColumnsPart1,
  ...commonApplicationColumnsPart2,
];

export const columnsExpertStatements = [
  ...commonApplicationColumnsPart1,
  { Header: 'Эксперт', accessor: 'expert' },
  ...commonApplicationColumnsPart2,
];

export const columnsCoordinatorStatements = [
  ...commonApplicationColumnsPart1,
  { Header: 'Координатор', accessor: 'coordinator' },
  ...commonApplicationColumnsPart2,
];

export const columnsContracts = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'ФИО', accessor: 'fullName' },
  { Header: 'Email', accessor: 'email' },
  { Header: 'Направление', accessor: 'directionTitle' },
  { Header: 'Статус заявления', accessor: 'applicationState' },
  { Header: 'Статус договора', accessor: 'contractState' },
];

export const columnsPersonalInformation = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'ФИО', accessor: 'fullName' },
  { Header: 'Email', accessor: 'email' },
  { Header: 'Дата создания аккаунта', accessor: 'createdAt' },
  { Header: 'Дата последнего изменения', accessor: 'updatedAt' },
  { Header: 'Гражданство', accessor: 'nationality' },
  { Header: 'Вид полученного образования', accessor: 'educationLevel' },
  { Header: 'Непрочитанные сообщения', accessor: 'message' },
];

export const columnsLogList = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Тип заявления', accessor: 'eventType' },
  { Header: 'Дата', accessor: 'eventTime' },
  { Header: 'ID заявления', accessor: 'applicationId' },
  { Header: 'ID абитуриента', accessor: 'applicantId' },
  { Header: 'ФИО абитуриента', accessor: 'applicantName' },
  { Header: 'ID инициатора', accessor: 'actorId' },
  { Header: 'ФИО инициатора', accessor: 'actorName' },
  { Header: 'Роль инициатора', accessor: 'actorRole' },
  { Header: 'Сообщение', accessor: 'message' },
];

export const columnsUploadQueue = [
  { Header: 'ID', accessor: 'userIdWithPrefix'},
  { Header: 'ФИО', accessor: 'fullName' },
  { Header: 'Наличие ошибок', accessor: 'hasErrors'},
  { Header: 'Последнее обновление', accessor: 'lastUpdateAt'},
  // { Header: 'Из ЕПГУ', accessor: 'fromEpgu'},
]

export const columnsAdminDirectoriesList = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Название', accessor: 'name' },
  { Header: 'Показ', accessor: 'visible' },
  { Header: 'Управление', accessor: 'controls' },
];
