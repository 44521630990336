import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import terms from 'i18n';

import {
  Contract,
  ContractLegalEntityPayerData,
  ContractType,
} from '../../../models/applications/contracts';
import { SelectOption } from '../../../models/common';
import { createToast } from '../../../redux/actions';
import { contractApi } from '../../../redux/api/applications/contract-api';
import useAppDispatch from '../../common/useAppDispatch';
import { useGetContractPaymentTypesSelectProps } from './useGetContractPaymentTypesSelectProps';
import { useGetCreateContractSelectOptions } from './useGetDirectionSelectOptions';

type JuridicalContractForm = ContractLegalEntityPayerData & {
  directionId: SelectOption;
  paymentForm: SelectOption;
};

const useJuridicalContractForm = (
  contract?: Contract,
  isFillingStatementsForm?: boolean,
  onSubmitProp?: (res) => void
) => {
  const navigate = useNavigate();
  const [createContract] = contractApi.useLazyCreateQuery();
  const [updateContract] = contractApi.useUpdateContractMutation();
  const dispatch = useAppDispatch();

  const directionOptions = useGetCreateContractSelectOptions(
    Boolean(contract),
    contract?.applicationId
  );
  const paymentForms = useGetContractPaymentTypesSelectProps();

  const { register, handleSubmit, watch, setValue, getValues, reset, ...rest } =
    useForm<JuridicalContractForm>({ mode: 'all' });

  useEffect(() => {
    if (contract && directionOptions.length && paymentForms.length) {
      if (contract?.legalEntityPayerData) {
        reset({
          ...contract?.legalEntityPayerData,
          directionId: directionOptions.find(
            d => d.value === contract.applicationDirectionId
          ),
          paymentForm: paymentForms.find(
            p => p.value === contract.paymentType.id
          ),
        });
      }

      setValue(
        'directionId',
        directionOptions.find(d => d.value === contract.applicationDirectionId)!
      );

      setValue(
        'paymentForm',
        paymentForms.find(p => p.value === contract.paymentType.id)!
      );
    }
  }, [directionOptions.length, paymentForms.length]);

  const onSubmit = handleSubmit(data => {
    let request;
    if (contract) {
      request = updateContract({
        id: contract.id,
        contractType: ContractType.JURIDICAL,
        paymentTypeId: data.paymentForm.value,
        applicationDirectionId: data.directionId.value,
        contractLegalEntityPayerData: {
          ...data,
        },
      }).unwrap();
    } else {
      request = createContract({
        contractType: ContractType.JURIDICAL,
        paymentTypeId: data.paymentForm.value,
        applicationDirectionId: data.directionId.value,
        contractLegalEntityPayerData: {
          ...data,
        },
      })
        .unwrap()
        .catch(() =>
          dispatch(createToast(terms.CREATE_CONTRACT_ERROR, 'danger'))
        );
    }

    request.then(res => {
      if (isFillingStatementsForm) {
        navigate('..');
      }
      onSubmitProp?.(res);
    });
  });

  return {
    onSubmit,
    setValue,
    getValues,
    watch,
    ...rest,
    directionOptions,
    paymentForms,
  };
};

export default useJuridicalContractForm;
