import {
  ApplicationId,
  ApplicationView,
} from '../../../../models/applications/applications';
import { apiSlice } from '../../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/applications/admin-managed`;

export const adminApplicationsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    setModeratorForApplication: builder.mutation<
      ApplicationView,
      {
        applicationId: ApplicationId;
        moderatorId: number;
      }
    >({
      query: ({ applicationId, moderatorId }) => ({
        url: `${baseUrl}/set-moderator/${applicationId}`,
        method: 'POST',
        params: {
          moderatorId,
        },
      }),
    }),

    setApplicationHasOriginalDocs: builder.mutation<
      ApplicationView,
      {
        applicationId: ApplicationId;
        status: boolean;
      }
    >({
      query: ({ applicationId, status }) => ({
        url: `${baseUrl}/set-has-original-documents/${applicationId}`,
        method: 'POST',
        params: {
          status,
        },
      }),
    }),

    forceWithdrawApplication: builder.mutation<ApplicationView, ApplicationId>({
      query: id => ({
        url: `${baseUrl}/approve-withdraw/${id}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useSetModeratorForApplicationMutation,
  useSetApplicationHasOriginalDocsMutation,
  useForceWithdrawApplicationMutation,
} = adminApplicationsApi;
