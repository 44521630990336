import { createAsyncThunk } from '@reduxjs/toolkit';

import { chatSlice } from 'redux/slices/common/chat-slice';
import { api } from 'services/api-client';

export const {
  setChatOpened,
  setChatType,
  setChatSocket,
  setChatViewed,
  addUpdateChatMessage,
  applyToChatMessages,
  resetChatMessages,
  incUnreadCountById,
  resetUnreadCountByType,
  resetUnreadCountById,
} = chatSlice.actions;

export const getUserChats = createAsyncThunk(
  'chat/userChats',
  async ({ applicantId }: { applicantId: number }) => {
    const response = await api.chat.getUserChats(applicantId);
    return response.data;
  }
);

export const getUnreadCount = createAsyncThunk(
  'chat/unreadCount',
  async ({ chatId }: { chatId: number }) => {
    const response = await api.chat.getUnreadCount(chatId);
    return response.data;
  }
);

export const getUnreadCountApplicant = createAsyncThunk(
  'chat/unreadCountApplicant',
  async ({ applicantId }: { applicantId: number }) => {
    const response = await api.chat.getUnreadCountApplicant(applicantId);
    return response.data;
  }
);

export const getChatMessages = createAsyncThunk(
  'chat/messages',
  async ({ chatId }: { chatId: number }) => {
    const response = await api.chat.getMessages(chatId);
    return response.data;
  }
);
