import React, { FC } from 'react';

import { NavLink } from 'react-router-dom';

import useAdminUserListElement from 'hooks/admin/useAdminUserListElement';
import useAppSelector from 'hooks/common/useAppSelector';
import { selectUserRole } from 'redux/selectors';
import { formatDateTimeToRuFormat } from 'utils/date';

import { UserRole } from '../../../models/user';
import { userManagementApi } from '../../../redux/api/staff/admin/user-management-api';
import { UserDataByStaff } from '../../../redux/slices/staff/userManagementSlice';

type AdminUserListElementProps = {
  readonly user: UserDataByStaff;
};

const AdminUserListElement: FC<AdminUserListElementProps> = ({ user }) => {
  const {
    blockUser,
    unblockUser,
    archiveUser,
    restoreUser,
    activateUser,
    requestPasswordReset,
    onQuestionnaireImpersonate,
  } = useAdminUserListElement();

  const role = useAppSelector(selectUserRole);
  const [resetLoginAttempts] =
    userManagementApi.useResetLoginAttemptCountMutation();
  const resetUserLoginAttempts = user => resetLoginAttempts(user.userView.id);

  return (
    <div className="admin-user-element">
      <div className="admin-user-element__info">
        <h2>
          {user.generalDataView
            ? `${user.generalDataView.surname || ''} ${
                user.generalDataView.name || ''
              } ${user.generalDataView.patronymic || ''}`
            : '-'}
        </h2>
        <p>
          <b>ID пользователя:</b> {user.userView.idWithPrefix}
        </p>
        <p>
          <b>Почта:</b>{' '}
          <a href={`mailto:${user.userView.email}`}>{user.userView.email}</a>
        </p>
        <p>
          <b>Дата рождения:</b> {user.generalDataView?.birthday || '-'}
        </p>
        <p>
          <b>Дата регистрации:</b>{' '}
          {formatDateTimeToRuFormat(user.userView.createdAt)}
        </p>
        <p>
          <b>Статус аккаунта:</b>{' '}
          {user.userView.blocked
            ? 'Заблокированный'
            : user.userView.archived
            ? 'Неактивный'
            : user.userView.activated
            ? 'Активный'
            : 'Неактивный'}
        </p>
      </div>

      {role === UserRole.SUPPORT && user.userView.archived && (
        <div className="admin-user-element__actions">
          <h2>Действия:</h2>
          <div className="admin-user-element__actions-btn">Активировать</div>
        </div>
      )}

      {role === UserRole.ADMIN && (
        <div className="admin-user-element__actions">
          <h2>Действия:</h2>

          <NavLink
            to={'/admin/users/impersonate/' + user.userView?.id || ''}
            target="_blank"
            onClick={onQuestionnaireImpersonate}
          >
            <div className="admin-user-element__actions-btn">
              Зайти в личный кабинет пользователя
            </div>
          </NavLink>

          <br />

          {!user.userView.archived && (
            <div
              className="admin-user-element__actions-btn"
              onClick={() =>
                requestPasswordReset(
                  user.userView.username,
                  user.userView.email
                )
              }
            >
              Отправить запрос на сброс пароля
            </div>
          )}

          {!user.userView.archived && (
            <div
              className="admin-user-element__actions-btn"
              onClick={() => resetUserLoginAttempts(user)}
            >
              Сбросить попытки входа
            </div>
          )}

          <br />

          {!user.userView.archived && user.userView.blocked && (
            <div
              className="admin-user-element__actions-btn btn-grey"
              onClick={() => unblockUser(user.userView.id)}
            >
              Разблокировать
            </div>
          )}

          {!user.userView.archived && !user.userView.blocked && (
            <div
              className="admin-user-element__actions-btn btn-grey"
              onClick={() => blockUser(user.userView.id)}
            >
              Заблокировать
            </div>
          )}

          {!user.userView.activated && (
            <div
              className="admin-user-element__actions-btn btn-grey"
              onClick={() => activateUser(user.userView.id)}
            >
              Активировать
            </div>
          )}

          {!user.userView.archived && (
            <div
              className="admin-user-element__actions-btn btn-red"
              onClick={() => archiveUser(user.userView.id)}
            >
              Удалить пользователя
            </div>
          )}
          {user.userView.archived && (
            <div
              className="admin-user-element__actions-btn"
              onClick={() => restoreUser(user.userView.id)}
            >
              Восстановить пользователя
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminUserListElement;
