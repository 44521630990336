import {
  DirectionRequestType,
  AddDirectionsToApplication,
  ApplicationId,
  ApplicationView,
  CreateApplicationRequest,
  GetDesiredDirectionsResponse,
  SetDesiredDirectionRequest,
  OriginalEducationDocumentSubmission,
  AgreementResponse,
  OriginalEducationDocumentSubmissionRequest,
} from 'models/applications/applications';
import { apiSlice } from 'redux/api/api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/applications/user-managed`;
const staffManagedBaseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/applications/staff-managed`;
export const applicationsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDirectionAgreements: builder.mutation<
      AgreementResponse[],
      ApplicationId
    >({
      query: applicationId => ({
        url: `${baseUrl}/agreement?applicationId=${applicationId}`,
        method: 'GET',
      }),
    }),

    submitDirectionAgreement: builder.mutation<
      undefined,
      {
        agreement: boolean;
        applicationDirectionId: number;
      }
    >({
      query: data => ({
        url: `${baseUrl}/set-agreement?applicationDirectionId=${data.applicationDirectionId}&agreement=${data.agreement}`,
        method: 'POST',
      }),
    }),

    getOriginalEducationDocumentSubmission: builder.query<
      OriginalEducationDocumentSubmission,
      ApplicationId
    >({
      query: applicationId => ({
        url: `${baseUrl}/${applicationId}/original-education-document-submission`,
        method: 'GET',
      }),
    }),

    submitOriginalEducationDucument: builder.mutation<
      OriginalEducationDocumentSubmission,
      {
        applicationId: ApplicationId;
        request: OriginalEducationDocumentSubmissionRequest;
      }
    >({
      query: ({ applicationId, request }) => ({
        url: `${staffManagedBaseUrl}/${applicationId}/original-education-document-submission`,
        method: 'POST',
        body: request,
      }),
    }),

    revokeOriginalEducationDucument: builder.mutation<
      OriginalEducationDocumentSubmission,
      ApplicationId
    >({
      query: applicationId => ({
        url: `${staffManagedBaseUrl}/${applicationId}/original-education-document-submission`,
        method: 'DELETE',
      }),
    }),

    createApplication: builder.mutation<
      ApplicationView,
      CreateApplicationRequest
    >({
      query: request => ({
        url: `${baseUrl}/create`,
        method: 'POST',
        body: request,
      }),
    }),

    getApplicationById: builder.query<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/${applicationId}`,
        method: 'GET',
      }),
    }),

    deleteApplication: builder.mutation<void, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/delete/${applicationId}`,
        method: 'DELETE',
      }),
    }),

    submitApplication: builder.mutation<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/submit/${applicationId}`,
        method: 'POST',
      }),
    }),

    submitApplicationEditing: builder.mutation<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/submit-editing/${applicationId}`,
        method: 'POST',
      }),
    }),

    submitDirectionsEditing: builder.mutation<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/submit-directions-editing/${applicationId}`,
        method: 'POST',
      }),
    }),

    takeBack: builder.mutation<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/take-back/${applicationId}`,
        method: 'POST',
      }),
    }),

    withdrawApplication: builder.mutation<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/withdraw/${applicationId}`,
        method: 'POST',
      }),
    }),

    takeToDirectionsEditing: builder.mutation<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/take-to-directions-editing/${applicationId}`,
        method: 'POST',
      }),
    }),

    getApplications: builder.mutation<ApplicationView[], number>({
      query: userId => ({
        url: `${baseUrl}/all`,
        method: 'GET',
      }),
    }),

    addDirections: builder.query<
      ApplicationView,
      {
        readonly applicationId: number;
        readonly request: AddDirectionsToApplication;
        readonly requestType?: DirectionRequestType;
      }
    >({
      query: ({ applicationId, request, requestType }) => ({
        url: `${baseUrl}/set-directions/${applicationId}`,
        method: 'POST',
        body: request,
        params: {
          requestType,
        },
      }),
    }),

    deleteApplicationDirection: builder.mutation<
      ApplicationView,
      {
        readonly applicationId: number;
        readonly applicationDirectionId: number;
      }
    >({
      query: ({ applicationId, applicationDirectionId }) => ({
        url: `${baseUrl}/${applicationId}/delete-direction/${applicationDirectionId}`,
        method: 'DELETE',
      }),
    }),

    setDesiredDirection: builder.mutation<
      ApplicationView,
      SetDesiredDirectionRequest
    >({
      query: request => ({
        url: `${baseUrl}/desired-direction`,
        method: 'POST',
        body: request,
      }),
    }),

    getDesiredDirections: builder.query<
      GetDesiredDirectionsResponse,
      { applicationId: number }
    >({
      query: ({ applicationId }) => ({
        url: `${baseUrl}/desired-direction/${applicationId}`,
        method: 'GET',
      }),
    }),

    setAgreement: builder.mutation<
      void,
      {
        readonly applicationDirectionId: number;
        readonly agreement: boolean;
      }
    >({
      query: ({ agreement, applicationDirectionId }) => ({
        url: `${baseUrl}/set-agreement`,
        method: 'POST',
        params: {
          agreement,
          applicationDirectionId,
        },
      }),
    }),

    setCheckMarked: builder.mutation<
      void,
      {
        readonly applicationId: number;
        readonly checkmarked: boolean;
      }
    >({
      query: ({ applicationId, checkmarked }) => ({
        url: `${baseUrl}/set-checkmarked`,
        method: 'POST',
        params: {
          applicationId,
          checkmarked,
        },
      }),
    }),
  }),
});

export const {
  useGetDirectionAgreementsMutation,
  useSubmitDirectionAgreementMutation,
  useGetOriginalEducationDocumentSubmissionQuery,
  useSubmitOriginalEducationDucumentMutation,
  useRevokeOriginalEducationDucumentMutation,
  useCreateApplicationMutation,
  useGetApplicationsMutation,
  useDeleteApplicationMutation,
  useLazyAddDirectionsQuery,
  useSetDesiredDirectionMutation,
  useGetDesiredDirectionsQuery,
  useSubmitApplicationMutation,
  useSubmitApplicationEditingMutation,
  useSubmitDirectionsEditingMutation,
  useWithdrawApplicationMutation,
  useGetApplicationByIdQuery,
  useTakeToDirectionsEditingMutation,
  useDeleteApplicationDirectionMutation,
} = applicationsApi;
