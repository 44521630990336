import { DateString, LocalizedEntityWithExternalId } from '../common';
import { ExternalEntityNamedResponse } from './applications';
import {
  ApplicationEducationLevel,
  ApplicationState,
  Trajectory,
} from './enum';

export enum ContractState {
  DRAFT = 'DRAFT',
  PROJECT = 'PROJECT',
  UNDER_CONSIDERATION = 'UNDER_CONSIDERATION',
  EDITING = 'EDITING',
  EDITED = 'EDITED',
  APPROVED = 'APPROVED',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
}

export enum ContractType {
  SELF_PAYMENT = 'SELF_PAYMENT',
  THIRD_PARTY = 'THIRD_PARTY',
  MATERNAL = 'MATERNAL',
  JURIDICAL = 'JURIDICAL',
}

export type Contract = {
  readonly id: number;
  readonly userId: number;
  readonly state: ContractState;
  readonly applicationDirectionId: number;
  readonly directionId: number;
  readonly directionCode: string;
  readonly directionTitle: string;
  readonly applicationTrajectory: Trajectory;
  readonly directionEducationForm: LocalizedEntityWithExternalId;
  readonly contractType: ContractType;
  readonly directionTitleForeign: string;
  readonly educationProgramTitle?: string;
  readonly educationProgramTitleForeign?: string;
  readonly paymentType: LocalizedEntityWithExternalId;
  readonly isPaid: boolean;
  readonly externalDocumentId: number;
  readonly thirdPartyPayerData?: ContractThirdPartyPayerData;
  readonly legalEntityPayerData?: ContractLegalEntityPayerData;
  readonly applicationEducationLevel: ApplicationEducationLevel;
  readonly applicationId: number;
  readonly directionStudyInForeignLanguage: boolean;
  readonly uploadDate?: DateString;
  readonly uploadError?: string;
};

export type ContractView = {
  readonly id: number;
  readonly fullName: string;
  readonly email: string;
  readonly directionTitle: string;
  readonly applicationState: ApplicationState;
  readonly contractState: ContractState;
};

export type ContractThirdPartyPayerData = {
  readonly firstName: string;
  readonly lastName: string;
  readonly middleName: string;
  readonly birthDate: DateString;
  readonly placeOfBirth: string;
  readonly phone: string;
  readonly addressRegistration: string;
  readonly addressResidential: string;
  readonly docType: string;
  readonly series?: string;
  readonly number: string;
  readonly divisionCode?: string;
  readonly docExpirationDate?: DateString;
  readonly issuedAt: DateString;
  readonly issuedBy: string;
  readonly documentScanId: number;
  readonly attorneyRepresentative: boolean;
  readonly attorneyCustomer: boolean;
};

export type ContractLegalEntityPayerData = {
  readonly organizationName: string;
  readonly organizationShortName?: string;
  readonly address: string;
  readonly inn: string;
  readonly kpp: string;
  // readonly ogrn: string;
  readonly paymentAccount: string;
  readonly bankName: string;
  readonly correspondentAccount: string;
  readonly bankBic: string;
  readonly phone: string;
  readonly email: string;
  readonly managementBases: string;
  readonly generalManager: string;
};

/**
 * @deprecated
 */
export type ExternalEntityNamedResponseWithExternalId =
  ExternalEntityNamedResponse & {
    readonly externalId: string;
  };

export type ContractFilters = {
  readonly userId?: number;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly middleName?: string;
  readonly email?: string;
  readonly directionPaymentTypeIds?: number[];
  readonly directionEducationFormIds?: number[];
  readonly citizenshipCountryIds?: number[];
  readonly applicationsStates?: ApplicationState[];
  readonly contractStates?: ContractState[];
  readonly directionName?: string;
  readonly facultyIds?: number[];
  readonly applicationTrajectories?: Trajectory[];
  readonly educationPrograms?: number[];
  readonly moderators?: number[];
};
