import React from 'react';

import { Controller } from 'react-hook-form';

import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import AdminTableListPage from 'components/admin/AdminTableListPage';
import Button from 'components/common/ui-kit/Button';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import ReactSelect from 'components/common/ui-kit/Select/view';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import { showModal } from 'redux/actions';
import { userManagementApi } from 'redux/api/staff/admin/user-management-api';
import { selectUserRole } from 'redux/selectors';
import { createInputV2Props, createSelectProps } from 'utils/form-helpers';

enum Status {
  ACTIVE,
  ARCHIVED,
}

const statusOptions: SelectOption[] = [
  { label: 'Активный', value: Status.ACTIVE },
  { label: 'Удалён', value: Status.ARCHIVED },
];

enum Role {
  STAFF,
  ANY,
}

const roleOptions: SelectOption[] = [
  { label: 'Сотрудник', value: Role.STAFF },
  { label: 'Любая', value: Role.ANY },
];

export const defaultUsersFilters = {
  id: '',
  lastName: '',
  firstName: '',
  middleName: '',
  email: '',
  documentSerial: '',
  documentNumber: '',
  active: null,
  staffOnly: Role.ANY,
};

export const mapUserFiltersToRequest = (filters, map) => {
  const res = map(filters);
  const status = filters.status;
  if (status !== undefined) {
    res.active = filters.status === Status.ACTIVE;
    delete res.status;
  }
  const role = filters.role;
  res.staffOnly = role === Role.STAFF;
  delete res.role;
  return res;
};

export const renderUserAdministrationFilters = ({
  control,
  formState: { errors },
}) => {
  const inputV2Props = createInputV2Props(errors);
  const selectProps = createSelectProps(errors);
  return (
    <>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="ID пользователя"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Фамилия"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Имя"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="middleName"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Отчество"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Email"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="documentSerial"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Серия паспорта"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="documentNumber"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Номер паспорта"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption>
            options={statusOptions}
            placeholder="Статус"
            className="filter-form__field"
            isClearable
            {...selectProps(field, {
              options: statusOptions,
            })}
          />
        )}
      />
      <Controller
        name="role"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption>
            options={roleOptions}
            placeholder="Роль"
            className="filter-form__field"
            {...selectProps(field, {
              options: roleOptions,
            })}
          />
        )}
      />
    </>
  );
};

const AdministrationPage = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectUserRole);

  // const { getAllDataWithoutFilters, getAllData } = useDataListWithFilters({
  //   paginationAction: userManagementSlice.actions.setPage,
  //   selectState: selectUserManagementState,
  //   mapFiltersToRequest: () => ({}),
  //   useGetAllQuery: userManagementApi.useLazyGetAllUsersByAdminQuery,
  //   updateState: response =>
  //     userManagementSlice.actions.setUsers({
  //       ...response,
  //       users: (response as any)?.content,
  //       page: (response as any)?.number + 1,
  //       size: (response as any)?.size,
  //     }),
  //   additionalQueryParamsForRequest: {
  //     staffOnly: role === UserRole.ADMIN,
  //   },
  // });

  const onOpenModal = () => {
    dispatch(
      showModal({
        name: 'ADMIN_USER',
        data: {},
      })
    );
  };

  const [archive] = userManagementApi.useArchiveUserMutation();

  const editUser = item => {
    dispatch(
      showModal({
        name: 'ADMIN_USER',
        data: {
          adminUserIdData: {
            ...item,
          },
        },
      })
    );
  };

  const handleDeleteUser = item => archive(item.userView.id);

  return (
    <AdminTableListPage
      title="Администрирование пользователей"
      controls={
        <Button type="button" theme="success" onClick={() => onOpenModal()}>
          Добавить пользователя
        </Button>
      }
      storeKey="administration"
      query={{
        use: userManagementApi.useGetAllUsersByAdminQuery,
      }}
      displayCountLabel="Пользователей"
      columns={[
        {
          Header: 'ID',
          accessor: 'id',
          render: item => item.userView.idWithPrefix,
        },
        {
          Header: 'Email',
          accessor: 'email',
          render: item => item.userView.email,
        },
        {
          Header: 'ФИО',
          accessor: 'fullName',
          render: item =>
            item.generalDataView
              ? `${item.generalDataView.surname || ''} ${
                  item.generalDataView.name || ''
                } ${item.generalDataView.patronymic || ''}`
              : '-',
        },
        {
          Header: 'Активность',
          accessor: 'activity',
          render: item =>
            item.userView.blocked ? (
              'Заблокированный'
            ) : item.userView.archived ? (
              <span style={{ color: 'red' }}>Удален</span>
            ) : (
              <span style={{ color: 'green' }}>Активный</span>
            ),
        },
        {
          Header: 'Роль',
          accessor: 'role',
          render: item => terms[item.userView.role] || item.userView.role,
        },
        {
          accessor: 'controls',
          render: item => (
            <div className="administration-page-controls">
              <div className="buttons">
                <button
                  type="button"
                  style={{ marginRight: '1rem' }}
                  onClick={() => editUser(item)}
                >
                  <EditIcon />
                </button>
                <button type="button" onClick={() => handleDeleteUser(item)}>
                  <TrashIcon />
                </button>
              </div>
            </div>
          ),
        },
      ]}
      onRowClick={null}
      filtersConfig={{
        defaultValues: defaultUsersFilters,
        renderFilters: renderUserAdministrationFilters,
        mapFiltersToRequest: mapUserFiltersToRequest,
      }}
    />
  );
};

export default AdministrationPage;
